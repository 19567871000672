import React from 'react';

export default class Title extends React.Component {
    render() {
        return (
            <div className="title">
                <div className="smile"></div>
                <div className="title-bold">Plug</div>
                <div className="title-thin">Alert</div>
            </div>
        )
    }
}