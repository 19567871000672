import React from 'react';
import Splash from './splash';
import Form from './loginform';
import Navigator from './navigator';
import '../common/global.scss'
import './login.scss';
import { Redirect } from 'react-router-dom';

export default class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            splash: true,
            email: 'remo@restroomalert.com',
            password: 'restroom1',
            keep: false,
            isLoginEnabled: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.email && this.state.password !== this.state.isLoginEnabled) {
            this.setState({ isLoginEnabled: this.state.email && this.state.password })
        }
    }

    render() {
        const { splash, email, password, keep, isLoginEnabled } = this.state;
        const { login, isLoggedIn, redirectAfterLogin, org } = this.props;
        return (
            <div className="login">
                {splash && !isLoggedIn &&
                    <Splash
                        click={() => { this.setState({ splash: false }) }}
                    />
                }
                {!splash && !isLoggedIn &&
                    <Form
                        email={email}
                        password={password}
                        keep={keep}
                        isLoginEnabled={isLoginEnabled}
                        handleKeep={(ev) => { this.setState({ keep: ev.isChecked }) }}
                        handleEmail={(ev) => this.setState({ email: ev })}
                        handlePassword={(ev) => this.setState({ password: ev })}
                        handleLogin={() => { if (isLoginEnabled) { login(email, password); } }}
                        isLoginFailure={this.props.isLoginFailure}
                    />
                }
                {(isLoggedIn && redirectAfterLogin) &&
                    <Redirect to={redirectAfterLogin} />
                }
                {(isLoggedIn && !redirectAfterLogin) &&
                    <Navigator org={org} />
                }
            </div>
        )
    }
}