import React, { useState, useEffect } from "react";
import Title from "../common/title";
import AlertGroup from "./alertgroup";
import { Redirect } from "react-router-dom";
import * as c from "../common/const";

const Navigator = (props) => {
  const [status, setStatus] = useState(undefined);
  const [stat, setStat] = useState({});
  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + "/alerts/stat", { credentials: 'include' });
      const resj = await res.json();
      setStat(
        resj.reduce((acc, x) => {
          acc[x._id] = x.total;
          return acc;
        }, {})
      );
    })();
  }, []);

  return (
    <div className="navigator">
      {status && <Redirect to={"/list/" + status} />}
      {/* <div className="uptitle">McCarran International Airport</div> */}
  <div className="uptitle">{props.org}</div>
      <Title />
      <div className="menu-title">Select group of alerts to work</div>
      <AlertGroup
        caption="New"
        cnt={stat["new"] || 0}
        onClick={() => setStatus("new")}
        classAdd="bgnew"
        classAddCount="cnew bganew"
      />
      <AlertGroup
        caption="Open"
        cnt={stat["open"] || 0}
        onClick={() => setStatus("open")}
        classAdd="bgopen"
        classAddCount="copen bgaopen"
      />
      <AlertGroup
        caption="On Hold"
        cnt={stat["hold"] || 0}
        onClick={() => setStatus("hold")}
        classAdd="bghold"
        classAddCount="chold bgahold"
      />
      <AlertGroup
        caption="Closed"
        cnt={stat["closed"] || 0}
        onClick={() => setStatus("closed")}
        classAdd="bgclosed"
        classAddCount="cclosed bgaclosed"
      />
      <AlertGroup
        caption="Archived"
        onClick={() => setStatus("archived")}
        classAdd="bgarch"
        classAddCount="carch"
      />
    </div>
  );
};

export default Navigator;
