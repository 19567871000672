import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../reducers/main';
import { Grid, Menu } from 'semantic-ui-react';
import Title from '../common/title';

class Top extends React.Component {

    renderItem(name, ind, click) {
        return (
            <div className="item pointer" key={ind} onClick={click}>{name}</div>
        );
    }

    render() {
        const items = [{ name: 'Admin' }, { name: 'My Profile' }, { name: 'Settings' }, { name: 'Log Out', click: () => this.props.logout() }];
        return (
            <div className="top">
                <div className="top-logo">
                    <Title />
                </div>
                <div className="top-menu">
                    {items.map((x, i) => this.renderItem(x.name, i, x.click))}
                </div>
            </div>
        );
    }
}

  const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
  })
  
  export default connect(undefined, mapDispatchToProps)(Top);
  