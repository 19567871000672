import React from 'react';

export default class Checkbox extends React.Component  {
    render() {
		const { id, name, value, isChecked, handleChange, label, style } = this.props;
		return (
			<div className={`custom-check-box-wrapper pointer ${style}`}>
				<div className="custom-check-box">
					<input
						type="checkbox"
						value={value}
						checked={isChecked}
						label={name}
						onClick={(event) => handleChange({ id: id, isChecked: event.target.checked })}
						id={id}
					/>
					<label htmlFor={id}></label>
				</div>
				<label className="custom-check-box-label pointer" htmlFor={id}>{label}</label>
			</div>
		);
	}

}