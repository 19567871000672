import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import Container from "../../common/container";
import Table from "../../common/table";
import * as c from "../../common/const";
import Button from "../../common/button";
import "./users.scss";
import { go } from "../../../helpers/url";
import { sortCompare } from "../../../helpers/sort";
import Date from "../../common/date";
import PipeFilter from "../../common/pipefilter";
import Periods from "../../common/periods";
import FilterElement from "../../common/filterelement";
import Search from "../../common/search";
import { periodBack } from  '../../../helpers/period';

const Users = props => {
  const columns = [
    {
      id: "active",
      name: "Status",
      cl: "center",
      clh: "center",
      f: v =>
        renderIcon(`ra-icon-user cell-status ${v ? "ft-blue" : "ft-gray"}`)
    },
    {
      id: "name",
      name: "Name",
      cl: "left pointer",
      clh: "left",
      click: r => () => go("/portal/users/" + r._id)
    },
    { id: "permissions", name: "User Role", cl: "left", clh: "left" },
    { id: "identified", name: "Alerts ID'd", cl: "center", clh: "center" },
    { id: "assigned", name: "Assigned", cl: "center", clh: "center" },
    { id: "closed", name: "Closed", cl: "center", clh: "center" },
    {
      id: "last_action",
      name: "Last Action",
      cl: "center",
      clh: "center",
      f: v => <Date dt={v} />
    },
    {
      id: "mobile",
      name: "Mobile",
      cl: "center",
      clh: "center",
      f: v => renderIcon(v ? "ra-icon-phone-on cell-image ft-blue" : "")
    },
    {
      id: "email",
      name: "Email",
      cl: "center",
      clh: "center",
      f: v => renderIcon(v ? "ra-icon-email-on cell-image ft-blue" : "")
    }
  ];

  const [list, setList] = useState([]);
  const [sort, setSort] = useState({ column: "name", order: "asc" });
  const [active, setActive] = useState("");
  const [period, setPeriod] = useState(c.periods[2]);

  const [permission, setPermission] = useState();
  const [permissions, setPermissions] = useState([]);
  const [filter, setFilter] = useState("");
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [activity, setActivity] = useState();

  useEffect(() => {
    (async function getLocations() {
      const res = await fetch(c.BACK_URL + "/locations/shortList", {
        method: "GET",
        headers: { Authorization: props.token },
        credentials: 'include',
      });
      const resj = await res.json();
      setLocations(resj.map(x => ({ label: x.name, value: x._id })));
    })();
    (async function getPermissions() {
      const res = await fetch(c.BACK_URL + "/permissions/list", {
        method: "GET",
        headers: { Authorization: props.token }
      });
      const resj = await res.json();
      setPermissions(resj.map(x => ({ label: x.name, value: x.id })));
    })();
  }, []);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + `/users/listp?period=${periodBack(period.value)}`, { credentials: 'include' });
      const resj = await res.json();
      setList(resj);
    })();
  }, [period]);

  const renderIcon = iconClass => {
    return <div className={iconClass}></div>;
  };

  const renderTop = () => {
    return (
      <div className="top-filter">
        <div>
          <PipeFilter
            value={active}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "inactive", label: "Deactivated" }
            ]}
            changeValue={setActive}
          />
        </div>
        <Periods period={period} setPeriod={setPeriod} />
      </div>
    );
  };
  const renderFilter = () => {
    return (
      <div className="filter-container">
        <div className="filter">
          <FilterElement
            caption="All Locations"
            values={locations}
            value={location}
            setValue={setLocation}
          />
          <FilterElement
            caption="All Permissions"
            values={permissions}
            value={permission}
            setValue={setPermission}
          />
          <FilterElement
            caption="All Users"
            values={c.activity}
            value={activity}
            setValue={setActivity}
          />
        </div>
        <Search value={filter} setValue={setFilter} />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="buttons">
        <div className="buttons-title">
          <span className="ft-red">{sortedList.length}</span> Users
        </div>
        <div className="buttons-right">
          <Button caption="Export to Excel" enabled classAdd="r-button" />
          <Button caption="Print" enabled classAdd="r-button" />
          <Button
            caption="+ Add New"
            enabled={false}
            classAdd="submit pointer button-new"
            onClick={() => {
              document.location.href = "/portal/users/add";
            }}
          />
        </div>
      </div>
    );
  };

  let filteredList = list;
  if (permission) {
    const permName = permissions.find(x => x.value === permission).label;
    filteredList = filteredList.filter(x => x.permissions === permName);
  }
  if (active) {
    const v = active === "active";
    filteredList = filteredList.filter(x => x.active === v);
  }
  if (activity !== undefined) filteredList = filteredList.filter(x => ((x.identified + x.assigned + x.closed) > 0) === activity );
  if (filter) {
    let lfilter = filter.toLowerCase();
    filteredList = filteredList.filter(
      x =>
        (x.name && x.name.toLowerCase().includes(lfilter)) ||
        (x.permissions && x.permissions.toLowerCase().includes(lfilter)) ||
        (x.alerts &&
          String(x.alerts)
            .toLowerCase()
            .includes(lfilter)) ||
        (x.identified &&
          String(x.identified)
            .toLowerCase()
            .includes(lfilter)) ||
        (x.assigned &&
          String(x.assigned)
            .toLowerCase()
            .includes(lfilter)) ||
        (x.closed &&
          String(x.closed)
            .toLowerCase()
            .includes(lfilter))
    );
  }
  const sortedList = sort
    ? filteredList.sort((a, b) =>
        sortCompare(a[sort.column], b[sort.column], sort.order)
      )
    : filteredList;
  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Users", link: c.BASE_URL + "/portal/users" }
      ]}
    >
      <div className="users">
        <Banner
          title="Users"
          subtitle="Manage profiles and settings for Plug Technicians."
        />
        {renderTop()}
        {renderTitle()}
        {renderFilter()}
        <Table
          id="users"
          columns={columns}
          chosenColumns={columns}
          rows={sortedList}
          sort={sort}
          setSort={setSort}
        />
      </div>
    </Container>
  );
};

export default Users;
