import React from "react";
import "./App.css";
import Login from "./components/login/login";
import List from "./components/list/list";
import Assign from "./components/assign/assign";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Outlet from "./components/outlet/outlet";
import History from "./components/history/history";
import Update from "./components/update/update";
import { connect } from "react-redux";
import { fetchLogin, logout, loginSuccess } from "./reducers/main";

import Users from "./components/portal/users/users";
import Home from "./components/portal/home/home";
import UsersEdit from "./components/portal/users/usersEdit";
import Locations from "./components/portal/locations/locations";
import LocationEdit from "./components/portal/locations/locationEdit";
import Outlets from "./components/portal/outlets/outlets";
import OutletEdit from "./components/portal/outlets/outletEdit";
import Alert from "./components/portal/alerts/alert";
import Alerts from "./components/portal/alerts/alerts";
import Picture from "./components/common/picture";
import Gotit from "./components/common/gotit";

function PrivateRoute({ children, state, portal, ...rest }) {
  const { isLoggedIn } = state;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: portal ? "/portal/login" : "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

class App extends React.Component {
  componentDidMount() {
    const authSaved = localStorage.getItem("auth");
    if (authSaved) {
      try {
        const auth = JSON.parse(authSaved);
        if (auth && auth.isLoggedIn) {
          this.props.loginSuccess(
            auth.email,
            auth.token,
            auth.permissions,
            auth.user, 
            auth.org,
            auth.role
          );
        }
      } catch (err) {
        console.log("Can't parse auth JSON");
      }
    }
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Picture mayResize fullScreen url={this.props.image} />
          {this.props.gotit && <Gotit action={this.props.gotit} />}
          {!this.props.isLoggedIn && (
            <Login
              login={this.props.loginBack}
              isLoggedIn={this.props.isLoggedIn}
              isLoginFailure={this.props.isLoginFailure}
            />
          )}
          {this.props.isLoggedIn && (
            <div style={{ filter: this.props.image ? "blur(2px)" : "" }}>
              <Switch>
                <Route path="/login">
                  <Login
                    login={this.props.loginBack}
                    isLoggedIn={this.props.isLoggedIn}
                    isLoginFailure={this.props.isLoginFailure}
                    org={this.props.org}
                  />
                </Route>
                <Route path="/portal/login">
                  <Login
                    login={this.props.loginBack}
                    isLoggedIn={this.props.isLoggedIn}
                    isLoginFailure={this.props.isLoginFailure}
                  />
                </Route>
                <PrivateRoute state={this.props} path="/portal/users/:id">
                  <UsersEdit />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/home">
                  <Home />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/users">
                  <Users />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/locations/:id">
                  <LocationEdit />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/locations">
                  <Locations />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/outlets/:id">
                  <OutletEdit />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/outlets">
                  <Outlets />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/alert">
                  <Alert />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/portal/alerts">
                  <Alerts />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/list/:status">
                  <List
                  // plugs={[{ time: 1576430090, location: undefined, url: 'https://images-na.ssl-images-amazon.com/images/I/5102bADzThL._SY355_.jpg', type: 'new' },
                  // { time: 1576368000, location: undefined, url: 'https://media.rs-online.com/t_large/Y1752882-01.jpg', type: 'closed' },
                  // { time: 1576281600, location: undefined, url: 'https://assets.pcmag.com/media/images/573091-samsung-smartthings-wifi-smart-plug-main.jpg?width=1280&height=720', type: 'open' }]}
                  />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/assign/:id">
                  <Assign
                  // date={1576434743}
                  // url="https://images-na.ssl-images-amazon.com/images/I/5102bADzThL._SY355_.jpg"
                  // info="Top USB outlet won’t charge"
                  />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/outlet">
                  <Outlet
                    id={"CM1MT01A"}
                    type={"Combo Outlet"}
                    location="Terminal 1, Gate C"
                  />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/history/:id">
                  <History
                  // data={[{ user: 'Doug M', comment: 'Fixed elecctrical issue', dt: 1576581289 },
                  // { user: 'Max M', comment: 'Plugged in chaging station', dt: 1576408489 },
                  // { user: 'Remo K', comment: 'Cleaned out debris', dt: 1575371689 }
                  // ]}
                  />
                </PrivateRoute>
                <PrivateRoute state={this.props} path="/update/:id">
                  <Update
                  // date={1576236753}
                  // url={'https://images-na.ssl-images-amazon.com/images/I/5102bADzThL._SY355_.jpg'}
                  // info="Top USB outlet won’t charge"
                  // id={'CM1MT01A'}
                  // type={'Combo Outlet'}
                  // location="Terminal 1, Gate C"
                  />
                </PrivateRoute>
              </Switch>
            </div>
          )}
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  isLoginFailure: state.isLoginFailure,
  image: state.image,
  gotit: state.gotit,
  org: state.org
});

const mapDispatchToProps = dispatch => ({
  loginSuccess: (email, token, permissions, user, org, role) =>
    dispatch(
      loginSuccess({
        email: email,
        token: token,
        permissions: permissions,
        user: user, 
        org: org,
        role: role
      })
    ),
  logout: () => dispatch(logout()),
  loginBack: (email, password) => dispatch(fetchLogin(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
