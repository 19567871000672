import React, { useEffect, useState } from "react";
import Header from "../common/header";
import Status from "../common/status";
import Picture from "../common/picture";
import OutletCard from "../common/outletcard";
import CancelButton from "../common/buttonCancel";
import SubmitButton from "../common/buttonSubmit";
import { Dt } from "../common/dates";
import Select from "react-select";
import "./assign.scss";
import { withRouter } from "react-router-dom";
import * as c from "../common/const";
import { imageUrl, go } from "../../helpers/url";
import { toggleGotIt } from "../../reducers/main";
import { connect } from "react-redux";

const Assign = props => {
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [assignment, setAssignment] = useState({});

  const renderInfo = () => {
    return (
      <div className="assign-info">
        <Picture mayResize url={imageUrl(data.url)} />
        <div className="assign-info-label">"{data.description}"</div>
      </div>
    );
  };

  const renderField = (label, options, name, placeholder) => {
    return (
      <div className="assign-form-field">
        <div className="assign-form-field-label">{label}</div>
        <Select
          isSearchable
          options={options}
          value={options.find(x => x.value === assignment[name])}
          placeholder={placeholder}
          onChange={e => {
            setAssignment({ ...assignment, [name]: e.value });
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    (async function getData() {
      const res = await fetch(
        c.BACK_URL + "/alerts/" + props.match.params.id + "/details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: props.token
          }
        }
      );
      const resj = await res.json();
      setData(resj);
    })();
    (async function getUsers() {
      const res = await fetch(c.BACK_URL + "/users/short", { credentials: 'include' });
      const resj = await res.json();
      setUsers(resj.map(x => ({ value: x._id, label: x.name })));
    })();
    (async function getOutlets() {
      const res = await fetch(c.BACK_URL + "/outlets/listm", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: props.token
        }, 
        credentials: 'include',
      });
      const resj = await res.json();
      setOutlets(
        resj.map(x => ({
          value: x._id,
          label: x.name,
          location: x.location,
          type: x.type
        }))
      );
    })();
  }, []);
  useEffect(() => setAssignment({ ...assignment, notify: assignment.user }), [
    assignment.user
  ]);

  const updAssignment = async () => {
    const ob = { user: assignment.user, outlet: assignment.outlet };
    const res = await fetch(c.BACK_URL + "/alerts/assign/" + data._id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(ob)
    });
    if (res.status === 200) {
      props.gotit("logged");
      go("/list/new");
    }
  };

  let dt = new Dt(data.date);
  const outlet = outlets.find(x => x.value === assignment.outlet);
  return (
    <div className="assign">
      <Header title="Enter Outlet Code" add="" screen={2} />
      <Status
        caption={`${dt.time()}${dt.ampm()}, ${dt.dayOfWeek()}, ${dt.monthDay()}`}
        type="open"
      />
      {renderInfo()}
      <div className="assign-form">
        <div className="assign-form-title">Enter Outlet Code</div>
        {renderField("", outlets, "outlet")}
        {outlet && (
          <OutletCard
            id={outlet.label}
            type={outlet.type}
            location={outlet.location}
          />
        )}
        {renderField("Assign to:", users, "user", "Unassigned")}
        {renderField(
          "Notify:",
          [{ value: undefined, label: "All Users" }, ...users],
          "notify",
          "All Users"
        )}
      </div>
      <div className="overscroll">
        <div className="buttons">
          <CancelButton onClick={() => go("/list/new")} />
          <SubmitButton
            enabled={assignment.outlet && assignment.user}
            onClick={() => updAssignment()}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.token
});

const mapDispatchToProps = dispatch => ({
  gotit: act => dispatch(toggleGotIt(act))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Assign));
