import React from 'react';

export default class Slider extends React.Component {

	render() {
		const {
			active,
			value,
			id,
			onClick,
		} = this.props;
		const addClass = active ? 'blue-switch-bc' : 'gray-switch-bc';
		return (
			<div
				className={'slide-one ' + addClass + '-bc	'}
				onClick={onClick}
				id={'slide' + id}
			>
				<input
					type="checkbox" value={value} id={id || 'slideOne'} name={id || 'slideOne'} checked={active}
				/>
				<label
					// onClick={onClick}
					className={addClass}
				></label>
			</div>
		);
	}
}
