import React from 'react';
import Button from './button';

export default class ButtonCancel extends React.Component {
    render() {
        return (
            <Button caption="Cancel" classAdd="cancel" onClick={this.props.onClick} />
        );

    }
}