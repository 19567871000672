import moment from 'moment';
import React, { Component } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import './bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import './daterangepicker.css';

import Select from 'react-select';
import { periods, shortPeriods } from '../common/const';

export default class PeriodsCustom extends React.Component {

  getResult(x) {
    return { label: x.label, value: x.value };
  }

  getPeriodResult(picker) {
    let res;
    if (picker.chosenLabel === 'Custom Range') {
      res = '_' + picker.startDate.format('YYYYMMDD') + '|' + picker.endDate.format('YYYYMMDD');
    } else {
      let per = periods.find((x) => x.label === picker.chosenLabel) || periods[0];
      res = this.getResult(per);
    }
    return res;
  }

  render() {
    const { period, setPeriod } = this.props;
    let startDate = moment(), endDate = moment();
    if (period) {
      if (typeof(period) === 'object' && period.value) {
        let el = periods.find((x) => x.value === period.value);
        if (el) {
          startDate = el.period[0]; endDate = el.period[1];
        }
      } else if (typeof(period) === 'string' && period.startsWith('_')) {
        let da = period.replace('_', '').split('|');
        startDate = moment(da[0]);
        endDate = moment(da[1]);
      }
    }
    return (
      <div className="toolbar title-periods">
        <div className="toolbar-left">
          {shortPeriods.map((x, i) => (
            <div className="toolbar-left-container" key={x.value}>
              {i !== 0 &&
                <span className="title-periods-delimiter pointer">|</span>
              }
              <span className="'title-periods-value pointer" onClick={() => setPeriod(this.getResult(x))}>
                <span className={x.value === period.value ? 'ft-black' : ''}>{x.label}</span>
              </span>
            </div>
          ))}
          <DateRangePicker
            startDate={startDate.format('MM/DD/YYYY')}
            endDate={endDate.format('MM/DD/YYYY')}
            showCustomRangeLabel
            locale={{ firstDay: 1 }}
            ranges={periods.reduce((res, x) => { return Object.assign(res, { [x.label]: x.period }); }, {})}
            onApply={(ev, picker) => setPeriod(this.getPeriodResult(picker))}
          >
            <Select
              className="period-select"
              value={period}
              isClearable={false}
              options={periods.map((x) => { return { value: x.value, label: x.label }; })}
              menuIsOpen={false}
              placeholder="Custom Period"
            />
          </DateRangePicker>
        </div>
      </div>
    );
  }
}