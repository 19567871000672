import React from 'react'
import '../common/global.scss';
import Select from 'react-select';
// import { Pagination } from 'react-bootstrap';

export default class Table extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showColumns: false,
            columns: this.getLS(this.columnsName(), props.chosenColumns)
        };
    }

    columnsName() {
        const { id } = this.props;
        return id + '_columns';
    }

    saveLS(name, value) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    getLS(name, def) {
        let v = localStorage.getItem(name);
        return v ? JSON.parse(v) : def;
    }

    saveColumnsLS() {
        const { columns } = this.state;
        this.saveLS(this.columnsName(), columns);
    }

    renderDef(x, cl) {
        return (<div className={cl} dangerouslySetInnerHTML={{ __html: x }}></div>);
    }

    columnName(v) {
        return v.name || v.id.charAt(0).toUpperCase() + v.id.slice(1);
    }

    renderCell(x, ind, c) {
        const fun = c.f || this.renderDef;
        const cl = c.cl || '';
        const lnk = !!c && c.click ? c.click(x) : undefined;
        return (
            <td key={'' + ind + '_' + c.id} className={c.tdcl} onClick={lnk}>
                {fun(x[c.id], cl, x)}
            </td>
        );
    }

    setSortOrder(col) {
        const { sort } = this.props;
        let res = undefined;
        if (!sort || sort.column !== col.id) {
            res = { column: col.id, order: 'asc' };
        } else {
            if (sort.order === 'asc') {
                res = { column: col.id, order: 'desc' };
            } else {
                res = undefined;
            }
        }
        if (this.props.setSort) {
            this.props.setSort(res);
        }
    }

    renderHeader(x, cntRows = 0) {
        const fun = x.fh ? x.fh : (v) => this.columnName(v);
        const addClass = this.props.sort && x.id === this.props.sort.column ? (this.props.sort.order === 'asc' ? 'ra-icon-asc' : 'ra-icon-desc') : '';
        const withBorder = cntRows === 0 ? 'bottom-border' : '';
        return (
            <th className={x.clh + ' pointer ' + withBorder} key={'header_' + x.id} onClick={x.noSort ? undefined : () => this.setSortOrder(x)}>{fun(x)}
                <span className={addClass}></span>
            </th>
        );
    }
    renderFooter(x, arr) {
        const fun = x.ff ? x.ff : () => '';
        return (
            <td className={x.clf} key={'footer_' + x.id} > {fun(x, arr)}</td>
        );
    }

    changeColumns(v, action, options) {
        const arrNames = v.map((x) => x.value.id);
        switch (action.action) {
            case 'select-option':
            case 'remove-value':
                this.setState({ columns: arrNames }, () => this.saveColumnsLS())
                break;
            case 'clear':
                this.setState({ columns: options.filter((x) => !!x.value.fixed).map((x) => x.value.id) }, () => this.saveColumnsLS())
                break;
            default:
        }
    }

    changeHideShow(propName, value) {
        this.setState({ showColumns: value });
        // switch (propName) {
        //     case 'checksCharts':
        //         this.setState({ checksCharts: value });
        //         break;
        //     case 'alertsCharts':
        //         this.setState({ alertsCharts: value });
        //         break;
        //     case 'columnsUsers':
        //         this.setState({ columnsUsers: value });
        //         break;
        //     case 'columnsRooms':
        //         this.setState({ columnsRooms: value });
        //         break;
        // }
        // this.saveLS('locations_' + propName, value);
    }

    renderShowHide() {
        const { showColumns } = this.state;
        return (
            <div className="charts-show-hide">
                <span className="black">Add/Remove Columns: </span>&nbsp;
            <span className={showColumns ? 'active pointer' : ' pointer'} onClick={() => this.changeHideShow('showColumns', true)}>Show</span>
                &nbsp;&nbsp;<span className="gray">|</span>&nbsp;&nbsp;
            <span className={!showColumns ? 'active pointer' : ' pointer'} onClick={() => this.changeHideShow('showColumns', false)}>Hide</span>
            </div>
        );
    }

    renderConditionn(v, cond) {
        if (cond) {
            return v;
        }
    }

    render() {
        const { columns, rows, chosenColumns, pageSize, pageSizeUnit, setPageSize, page, cnt, setPage, emptyCaption } = this.props;
        const { showColumns } = this.state;
        const pages = pageSize && pageSize > 0 ? Math.floor(cnt / Number(pageSize)) + 1 : 1;
        const filteredColumns = columns.filter((x) => this.state.columns.includes(x.id));
        const options = columns.map((x) => { return { value: x, label: this.columnName(x) }; });
        const shouldRenderFooter = filteredColumns.find((x) => x.ff !== undefined) !== undefined;
        const _setPage = (v) => setPage ? setPage(v) : '';
        const _page = page ? page : 1;
        // const showColumns = propColumnsShow === 'columnsUsers' ? this.state.columnsUsers : this.state.columnsRooms;
        return (
            <div className="users-table">
                {this.renderShowHide()}
                {showColumns &&
                    <Select
                        // className="period-select"
                        value={options.filter((x) => this.state.columns.includes(x.value.id))}
                        isClearable={true}
                        // onChange={(ev: ValueType<OptionType>) => this.setPeriod(ev as OptionType)}
                        onChange={(v, action) => this.changeColumns(v, action, options)}
                        isMulti
                        closeMenuOnSelect={false}
                        options={options}
                        placeholder="Columns"
                        styles={{
                            multiValue: (base, state) => {
                                return state.data.value.fixed ? { ...base, backgroundColor: 'gray' } : base;
                            },
                            multiValueLabel: (base, state) => {
                                return state.data.value.fixed
                                    ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                                    : base;
                            },
                            multiValueRemove: (base, state) => {
                                return state.data.value.fixed ? { ...base, display: 'none' } : base;
                            },
                        }} />
                }
                <table className="rtable">
                    <thead>
                        <tr>
                            {filteredColumns.map((x) => this.renderHeader(x, rows.length))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((x, ind) => (
                            <tr key={'row_ ' + ind}>
                                {filteredColumns.map((c) => this.renderCell(x, ind, c))}
                            </tr>
                        ))}
                    </tbody>
                    {shouldRenderFooter &&
                        <tfoot>
                            <tr>
                                {filteredColumns.map((x) => this.renderFooter(x, rows))}
                            </tr>
                        </tfoot>
                    }
                </table>
                {(rows.length === 0 && emptyCaption) &&
                    <div className="rtable-empty">
                        <div className="rtable-empty-title">No {emptyCaption}</div>
                        <div className="rtable-empty-subtitle">No {emptyCaption} match your filter or search criteria.</div>
                    </div>
                }
                {!!page &&
                    <div className="pagination">
                        <div className="pagination-size">
                            Show
                        <div className="pagination-select">
                                <Select
                                    className="filter-select"
                                    value={[pageSize]}
                                    isClearable={false}
                                    onChange={(v) => setPageSize ? setPageSize(v) : ''}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(x) => x}
                                    getOptionValue={(x) => x}
                                    options={[10, 50, 100, 150, 200, 250]}
                                    placeholder="All"
                                >
                                </Select>
                            </div>
                            {pageSizeUnit}
                        </div>
                        <div className="pagination-pages-container">
                            {/* <Pagination>
                                <div onClick={() => _setPage(1)}><Pagination.First disabled={page === 1} /></div>
                                <div onClick={() => _setPage(Math.max(1, (page || 1) - 1))}><Pagination.Prev disabled={page === 1} /></div>
                                {pages <= 5 &&
                                    <div className="pagination-pages">
                                        {
                                            Array(pages).fill(1).map((v, i) => (
                                                <Pagination.Item active={page === i + 1}>{i + 1}</Pagination.Item>
                                            ))
                                        }
                                    </div>
                                }
                                {pages > 5 &&
                                    <div className="pagination-pages">
                                        {this.renderConditionn((<Pagination.Ellipsis disabled />), _page >= 3)}

                                        {this.renderConditionn((<div onClick={() => _setPage(_page - 3)}><Pagination.Item >{_page - 3}</Pagination.Item></div>), _page === pages)}
                                        {this.renderConditionn((<div onClick={() => _setPage(_page - 2)}><Pagination.Item >{_page - 2}</Pagination.Item></div>), _page >= pages - 1)}

                                        {this.renderConditionn((<div onClick={() => _setPage(_page - 1)}><Pagination.Item >{_page - 1}</Pagination.Item></div>), _page > 1)}
                                        {this.renderConditionn((<div onClick={() => _setPage(_page)}><Pagination.Item active>{_page}</Pagination.Item></div>), true)}
                                        {this.renderConditionn((<div onClick={() => _setPage(_page + 1)}><Pagination.Item >{_page + 1}</Pagination.Item></div>), _page <= pages - 1)}

                                        {this.renderConditionn((<div onClick={() => _setPage(_page + 2)}><Pagination.Item >{_page + 2}</Pagination.Item></div>), _page <= 2)}
                                        {this.renderConditionn((<div onClick={() => _setPage(_page + 3)}><Pagination.Item >{_page + 3}</Pagination.Item></div>), _page === 1)}

                                        {this.renderConditionn((<Pagination.Ellipsis disabled />), _page <= pages - 2)}
                                    </div>
                                }
                                <div onClick={() => _setPage(Math.min(_page + 1, pages))}><Pagination.Next disabled={_page === pages} /></div>
                                <div onClick={() => _setPage(pages)}><Pagination.Last disabled={_page === pages} /></div>
                            </Pagination> */}
                        </div>
                    </div>
                }
            </div>
        );
    }

}