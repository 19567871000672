import React from "react";
import Select from "react-select";

const FilterElement = props => {
  
  const { caption, values, value, setValue } = props;

  const selectStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.hasValue ? "#0d83dd" : provided.color
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: state.hasValue ? "#0d83dd" : provided.color
    })
  };

  return (
    <Select
      className="filter-select"
      value={values.find(x => x.value === value)}
      isClearable={true}
      onChange={ev => setValue(ev ? ev.value : undefined)}
      options={[...[{ label: caption, value: "" }], ...values]}
      placeholder={caption}
      styles={selectStyles}
    />
  );
};

export default FilterElement;
