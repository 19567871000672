'use stricts'
import moment from 'moment';

export class Dt {

    dt;

    constructor(v) {
        this.dt = v ? moment.unix(v) : moment();
    }

    time() {
        return this.dt.format('h:mm')
    }
    ampm(up = true) {
        return this.dt.format(up ? 'A' : 'a')
    }
    date() {
        return this.dt.format('ddd, MMM D')
    }
    dateFull() {
        return this.dt.format('dddd, MMM D')
    }
    date2() {
        return this.dt.format('MM/DD/YY')
    }
    farFromNow() {
        return (this.dt.startOf('day').diff(moment().startOf('day'), 'days', true) >= 3)
    }
    customDate(fmt = 'MMM D') {
        if (this.farFromNow()) return this.date();
        else if (moment(this.dt).startOf('day').unix() === moment().startOf('day').unix()) return 'Today';
        else if (moment(this.dt).startOf('day').unix() === moment().startOf('day').subtract(1, 'day').unix()) return 'Yesterday';
        else if (moment(this.dt).startOf('year').unix() === moment().startOf('year').unix()) return this.dt.format(fmt);
        else return this.dt.format('MM/DD/YY');
    }

    ago() {
        return moment().to(this.dt);
    }
    all() {
        return this.dt.format();
    }
    dayOfWeek() {
        return this.dt.format('dddd');
    }
    monthDay() {
        return this.dt.format('MMM D');
    }
    mdd() {
        return this.dt.format('M/D');
    }
    diff(cnt = 2) {
        const _diff = this.dt.unix();
        const d = Math.floor(_diff / (24 * 3600));
        const h = Math.floor((_diff - d * 24 * 3600) / 3600);
        const m = Math.floor((_diff - d * 24 * 3600 - h * 3600) / 60);
        const s = Math.floor((_diff - d * 24 * 3600 - h * 3600 - m * 60));
        return [{ v: d, l: 'd'}, { v: h, l: 'h'}, { v: m, l: 'm'}, { v: s, l: 's'}].filter(x => x.v > 0).slice(0, cnt).map(x => `${x.v}${x.l}`).join(' ')
    }
    diffMore3Days() {
        const _diff = this.dt.unix();
        const d = _diff / (24 * 3600);
        return d > 3;
    }
}