import React from "react";
import { Dt } from "./dates";

const Date = props => {
  const { dt, showEmpty } = props;
  const _dt = new Dt(dt);
  if (dt !== 0 && dt !== undefined)
    // return (
    //   <div className="date">
    //     <div className="date-date-gray">{_dt.date2()}</div>
    //     <div className="date-time">{`${_dt.time()} ${_dt.ampm(false)}`}</div>
    //   </div>
    // );
    return (
      <div className="date">
        <div className="date-time">{_dt.time()}&nbsp;<small>{_dt.ampm(false)}</small></div>
        <div className="date-date-gray">{_dt.date2()}</div>
      </div>
    );
  else return <div className="date">{showEmpty ? "" : "Never"}</div>;
};

export default Date;


// $compare = date("mdy", $value);
// $yesterday = date('mdy',time() - (24 * 60 * 60));
// if ($compare == date("mdy")) {
//     $dt = 'Today';
// } else if ($compare == $yesterday) {
//     $dt = 'Yesterday';
// } else if (date("Y", $value) == date("Y")) {
//     $dt = date('M d', $value);
// } else  {
//     $dt = date('m/d/y', $value);
// }
// return empty($value) ? $def : "<h4 class='nowrap'>" . date('h:i', $value) . "<small>" . date(' a', $value) . "</small></h4><span class='date-color nowrap'>" . $dt . " </span>";
