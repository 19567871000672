import React, { useEffect, useState } from 'react';
import Banner from '../../common/banner';
import * as c from '../../common/const';
import Container from '../../common/container';
import { Form, Checkbox, Button, Input } from 'semantic-ui-react';
import './locations.scss';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

const LocationEdit = (props) => {

    const [data, setData] = useState('');
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [zones] = useState(moment.tz.names().map(x => ({ label: x, value: x })));

    const { permissions } = props;

    const params = useParams();
    const id = params.id === 'add' ? '' : params.id;

    // Load data
    useEffect(() => {
        (async function getData() {
            if (id) {
                const res = await fetch(c.BACK_URL + '/locations/' + id);
                const resj = await res.json();
                setData(resj);
            }
            const res = await fetch(c.BACK_URL + '/countries/list');
            const resj = await res.json();
            setCountries(resj.map((x) => ({ label: x.name, value: x._id })));
            const ress = await fetch(c.BACK_URL + '/states/list');
            const ressj = await ress.json();
            setStates(ressj.map((x) => ({ label: x.name, value: x._id })));
        })();
    }, [])

    useEffect(() => {
        const _phone = (data.phone || '').replace(/\D/g, '');
        let res = '';
        if (_phone.length > 0) res = '(' + _phone.substr(0, 3);
        if (_phone.length >= 3) res += ') ' + _phone.substr(3, 3);
        if (_phone.length >= 6) res += '-' + _phone.substr(6, 4);
        setData({ ...data, phone: res });
    }, [data.phone])

    const locationAdd = () => {
        (async function mod() {
            const ob = data;
            let res = await fetch(c.BACK_URL + '/locations/' + id, {
                method: id ? 'PUT' : 'POST', headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }, body: JSON.stringify(ob)
            });
            document.location.href = '/portal/locations';
        })();
    }

    const locationDelete = () => {
        (async function mod() {
            let res = await fetch(c.BACK_URL + '/locations/' + id, {
                method: 'DELETE'
            });
            document.location.href = '/portal/locations';
        })();
    }

    const renderField = (label, name) => {
        return (
            <Form.Field>
                <label>{label}</label>
                <input
                    placeholder={label}
                    value={data[name]}
                    onChange={(e) => setData({ ...data, [name]: e.target.value })}
                />
            </Form.Field>
        )
    }
    const renderSelect = (label, name, options) => {
        return (
            <Form.Field>
                <label>{label}</label>
                <Select
                    value={options.find((x) => x.value === data[name])}
                    placeholder={label}
                    options={options}
                    onChange={(e) => { setData({ ...data, [name]: e.value }) }}
                />
            </Form.Field>
        );
    }

    return (
        <Container breadcrumbs={[{ name: 'Home', link: c.BASE_URL + '/portal/home' }, { name: 'Locations', link: c.BASE_URL + '/portal/locations' }]} >
            <div className="locations-edit">
                <Banner
                    title="locations"
                    subtitle="Manage your locations and review metrics"
                />
                {id &&
                    <div className="delete">
                        <Button color="red" onClick={() => locationDelete()}>Delete</Button>
                    </div>
                }
                <Form>
                    {renderField('Name', 'name')}
                    {renderField('Address', 'address')}
                    {renderField('City', 'city')}
                    {renderSelect('State', 'state', states)}
                    {renderField('Zip/Postal Code', 'zip')}
                    {renderSelect('Country', 'country', countries)}
                    {renderField('Phone Number', 'phone')}
                    {renderSelect('Time Zone', 'timezone', zones)}
                    {renderField('Comments', 'comments')}
                    {permissions === c.PERMISSIONS.RAAdmin &&
                        renderField('Notes', 'notes')
                    }
                    <Button type='submit' onClick={() => locationAdd()}>Submit</Button>
                </Form>

            </div>
        </Container>
    );
}

const mapStateToProps = state => ({
    permissions: state.permissions,
})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(LocationEdit);