import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import Container from "../../common/container";
import Table from "../../common/table";
import * as c from "../../common/const";
import Button from "../../common/button";
import Date from "../../common/date";
import "./outlets.scss";
import { go } from "../../../helpers/url";
import { connect } from "react-redux";
import { sortCompare } from "../../../helpers/sort";
import PipeFilter from "../../common/pipefilter";
import Periods from "../../common/periods";
import FilterElement from "../../common/filterelement";
import Search from "../../common/search";
import { periodBack } from  '../../../helpers/period';

const Outlets = props => {
  const columns = [
    {
      id: "id",
      name: " ",
      cl: "center",
      clh: "center",
      f: (v, c, row) => renderOutlet(`bg${row.status || "no"}`)
    },
    {
      id: "name",
      name: "Outlet",
      cl: "left pointer",
      clh: "left",
      click: r => () => go("/portal/outlets/" + r._id)
    },
    { id: "location", name: "Location", cl: "left", clh: "left" },
    { id: "alerts", name: "Alerts", cl: "center ft-red", clh: "center" },
    { id: "last_description", name: "Last Alert", cl: "left", clh: "left" },
    { id: "last_comments", name: "Resolution", cl: "left", clh: "left" },
    {
      id: "last_added",
      name: "Last",
      cl: "left",
      clh: "left",
      f: v => <Date dt={v} />
    },
    { id: "last_updated_by", name: "Tech", cl: "left nowrp", clh: "left" }
  ];

  const [list, setList] = useState([]);
  const [sort, setSort] = useState({ column: "name", order: "asc" });
  const [active, setActive] = useState("");
  const [period, setPeriod] = useState(c.periods[2]);

  const [status, setStatus] = useState();
  const [filter, setFilter] = useState();
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [activity, setActivity] = useState();

  useEffect(() => {
    (async function getLocations() {
      const res = await fetch(c.BACK_URL + "/locations/shortList", {
        method: "GET",
        headers: { Authorization: props.token },
        credentials: 'include',
      });
      const resj = await res.json();
      setLocations(resj.map(x => ({ label: x.name, value: x._id })));
    })();
  }, []);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + `/outlets/list?period=${periodBack(period.value)}`, {
        method: "GET",
        headers: { Authorization: props.token },
        credentials: 'include',
      });
      const resj = await res.json();
      setList(resj);
    })();
  }, [period]);

  const renderOutlet = iconClass => {
    return (
      <div className={"outlet-cont " + iconClass}>
        <div className={"outlet cell-image "}></div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="top-filter">
        <div>
          <PipeFilter
            value={active}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "inactive", label: "Deactivated" }
            ]}
            changeValue={setActive}
          />
        </div>
        <Periods period={period} setPeriod={setPeriod} />
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <div className="filter">
          <FilterElement
            caption="All Locations"
            values={locations}
            value={location}
            setValue={setLocation}
          />
          <FilterElement
            caption="All Statuses"
            values={c.statuses}
            value={status}
            setValue={setStatus}
          />
          <FilterElement
            caption="All Outlets"
            values={c.activity}
            value={activity}
            setValue={setActivity}
          />
        </div>
        <Search value={filter} setValue={setFilter} />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="buttons">
        <div className="buttons-title">
          <span className="ft-red">{sortedList.length}</span> Outlets
        </div>
        <div className="buttons-right">
          <Button caption="Export to Excel" enabled classAdd="r-button" />
          <Button caption="Print" enabled classAdd="r-button" />
          <Button
            caption="+ Add New"
            enabled
            classAdd="submit pointer button-new"
            onClick={() => {
              document.location.href = "/portal/outlets/add";
            }}
          />
        </div>
      </div>
    );
  };

  let filteredList = list;
  if (status) filteredList = filteredList.filter(x => x.status === status);
  if (location) filteredList = filteredList.filter(x => x.location_id === location);
  if (activity !== undefined) filteredList = filteredList.filter(x => ((x.identified + x.assigned + x.closed) > 0) === activity );
  if (active) {
    const v = active === "active";
    filteredList = filteredList.filter(x => x.active === v);
  }
  if (filter) {
    let lfilter = filter.toLowerCase();
    filteredList = filteredList.filter(
      x =>
        (x.name && x.name.toLowerCase().includes(lfilter)) ||
        (x.location && x.location.toLowerCase().includes(lfilter)) ||
        (x.state && x.state.toLowerCase().includes(lfilter)) ||
        (x.alerts &&
          String(x.alerts)
            .toLowerCase()
            .includes(lfilter)) ||
        (x.last_description &&
          x.last_description.toLowerCase().includes(lfilter)) ||
        (x.tech && x.tech.toLowerCase().includes(lfilter))
    );
  }
  const sortedList = sort
    ? filteredList.sort((a, b) =>
        sortCompare(a[sort.column], b[sort.column], sort.order)
      )
    : filteredList;
  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Outlets", link: c.BASE_URL + "/portal/outlets" }
      ]}
    >
      <div className="users">
        <Banner
          title="Outlets"
          subtitle="Manage outlets across multiple facilities"
        />
        {renderTop()}
        {renderTitle()}
        {renderFilter()}
        <Table
          id="outlets"
          columns={columns}
          chosenColumns={columns}
          rows={sortedList}
          sort={sort}
          setSort={setSort}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  token: state.token
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Outlets);
