import React from "react";
import { connect } from "react-redux";
import { setImage } from "../../reducers/main";

const Picture = props => {
  const { url, fullScreen, mayResize, fixedSize, size } = props;
  //   const [fullScreen, setFullScreen] = useState(false);

  const commonStyle = {
    backgroundImage: `url(${url})`,
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    borderRadius: "6px"
  };
  const fullScreenStyle = {
    position: "absolute",
    top: "10px",
    left: 0,
    zIndex: 10,
    height: "100vw"
  };
  const usualSceenStyle = {};
  const _size = size ? size : { width: "100%", height: "100%" };
  const style = fullScreen
    ? { ...commonStyle, ...fullScreenStyle, ...size }
    : { ...commonStyle, ...usualSceenStyle, ...size };


  const res = url ? (
    <div
      className="plug-picture"
      style={style}
      onClick={() => {
        if (mayResize) props.setImage(url === props.image ? undefined : url);
      }}
    >
      {!fixedSize && (
        <img
          src={`${url}`}
          style={{ width: "100%", height: "100%", visibility: "hidden" }}
        />
      )}
    </div>
  ) : (
    <div></div>
  );

  return res;
};

const mapStateToProps = state => ({
  image: state.image
});

const mapDispatchToProps = dispatch => ({
  setImage: image => dispatch(setImage({ image: image }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Picture);
