import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import * as c from "../../common/const";
import { doUpload } from "../../../helpers/url";
import Container from "../../common/container";
import { Form, Checkbox, Button, Input } from "semantic-ui-react";
import "./outlets.scss";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import Picture from "../../common/picture";

const OutletEdit = props => {
  const [data, setData] = useState("");
  const [locations, setLocations] = useState([]);
  const { permissions } = props;

  const params = useParams();
  const id = params.id === "add" ? "" : params.id;

  // Load data
  useEffect(() => {
    (async function getData() {
      if (id) {
        const res = await fetch(c.BACK_URL + "/outlets/" + id, {
          method: "GET",
          headers: { Authorization: props.token }
        });
        const resj = await res.json();
        setData(resj);
      }
      const res = await fetch(c.BACK_URL + "/locations/shortlist", { credentials: 'include' });
      const resj = await res.json();
      const locations = resj.map(x => ({ label: x.name, value: x._id }));
      setLocations(locations);
    })();
  }, []);

  const outletAdd = () => {
    (async function mod() {
      const ob = {
        name: data.name,
        code: data.code,
        location: data.location,
        comments: data.comments,
        notes: data.notes,
        image: data.image,
        active: data.active
      };
      let res = await fetch(c.BACK_URL + "/outlets/" + id, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: props.token
        },
        body: JSON.stringify(ob),
        credentials: 'include'
      });
      document.location.href = "/portal/outlets";
    })();
  };

  const outletDelete = () => {
    (async function mod() {
      let res = await fetch(c.BACK_URL + "/outlets/" + id, {
        method: "DELETE"
      });
      document.location.href = "/portal/outlets";
    })();
  };

  const renderHeader = () => {
    const loc = data.location
      ? locations.find(x => x.value === data.location)
      : {};
    return (
      <div className="header-edit">
        <div className="header-banner">
          <div className="header-banner-outlet-container">
            <div className="header-banner-outlet"></div>
          </div>
          <div className="header-title">
            <div className="header-title-code-cn">
              <div className="header-title-code">{data.code} </div>
              <div className="header-title-name">{data.name}</div>
            </div>

            <div className="header-title-location">{loc ? loc.label : ""}</div>
          </div>
          <div
            className="header-banner-cancel pointer"
            onClick={() => {
              document.location.href = "/portal/outlets";
            }}
          >
            <span className="glyphicon glyphicon-remove"></span> Cancel
          </div>
        </div>
        <div className="header-subtitle">Edit Outlet</div>
      </div>
    );
  };

  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Outlets", link: c.BASE_URL + "/portal/outlets" }
      ]}
    >
      <div className="outlets-edit">
        {/* <Banner
                    title="Outlets"
                    subtitle="Manage outlets across multiple facilities"
                /> */}
        {renderHeader()}
        {/* {id && (
          <div className="delete">
            <Button color="red" onClick={() => outletDelete()}>
              Delete
            </Button>
          </div>
        )} */}
        <div className="outlets-edit-lr">
          <div className="outlets-edit-left">
            <Form>
              <div className="outlets-edit-left-title">Description</div>
              <Form.Field>
                <label>Location</label>
                <Select
                  value={locations.find(x => x.value === data.location)}
                  placeholder="Location"
                  options={locations}
                  onChange={e => {
                    setData({ ...data, location: e.value });
                  }}
                />
                {/* <input
                            value={data.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        /> */}
              </Form.Field>
              <Form.Field>
              <label>Status</label>
              <Form.Group>
                <Form.Radio
                  label="Active"
                  value="sm"
                  checked={data.active}
                  onChange={() => setData({...data, active: true})}
                />
                <Form.Radio
                  label="Inactive"
                  value="md"
                  checked={!data.active}
                  onChange={() => setData({...data, active: false})}
                />
              </Form.Group>
              </Form.Field>
              <Form.Field>
                <label>Outlet Code</label>
                <input
                  placeholder="Code"
                  value={data.code}
                  onChange={e => setData({ ...data, code: e.target.value })}
                />
                <div className="subscript">
                  Enter the unique Outlet Code assigned to this unit
                </div>
              </Form.Field>
              <Form.Field>
                <label>Outlet Name</label>
                <input
                  placeholder="Name"
                  value={data.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                />
                <div className="subscript">
                  Friendly name for this outlet (not required)
                </div>
              </Form.Field>
              {/* <label className="subscript">Friendly name for this outlet (not required)</label> */}
              <Form.Field>
                <label>Comments</label>
                <input
                  placeholder="Comments"
                  value={data.comments}
                  onChange={e => setData({ ...data, comments: e.target.value })}
                />
              </Form.Field>
              {permissions === c.PERMISSIONS.RAAdmin && (
                <Form.TextArea
                  label="Internal Notes"
                  placeholder="Notes"
                  value={data.notes}
                  onChange={e => setData({ ...data, notes: e.target.value })}
                />
              )}
              <div className="upd-buttons">
                <div
                  className="upd-buttons-update pointer"
                  onClick={() => outletAdd()}
                >
                  <span className="glyphicon glyphicon-ok"></span> Update Outlet
                </div>
                <div className="upd-buttons-or">or</div>
                <div
                  className="upd-buttons-cancel pointer"
                  onClick={() => {
                    document.location.href = "/portal/outlets";
                  }}
                >
                  Cancel
                </div>
              </div>
            </Form>
          </div>
          <div className="outlets-edit-right">
            <div
              className="pointer outlets-edit-label"
              onClick={() => {
                document.getElementById("fileinput").click();
              }}
            >
              <span className="glyphicon glyphicon-pencil"></span> Edit
            </div>
            <div className="upload">
              <input
                id="fileinput"
                type="file"
                accept="image/*"
                onChange={async evt =>
                  setData({
                    ...data,
                    image: await doUpload(evt.target.files[0])
                  })
                }
              />
            </div>
            {data.image && (
              <div className="image">
                <Picture
                  url={`${c.BACK_URL}/images/${data.image}`}
                  mayResize={false}
                  size={{ width: "100%", height: "40vh" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  permissions: state.permissions,
  token: state.token
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OutletEdit);
