import React from 'react';

export default class Radio extends React.Component {

	render() {
		const {
			checked,
			onClick,
			id,
			name, value, label
		} = this.props;
		// const addClass = checked ? activeClass : inactiveClass;
		return (
			<label
				className="option"
				onClick={onClick}
				htmlFor={name}
			>{label}
				<input type="radio" value={value} id={id} name={name} checked={checked} />
				<span className="option-mod" />
			</label>
		);
	}
}
