import React, { useEffect, useState } from "react";
import Header from "../common/header";
import Status from "../common/status";
import Picture from "../common/picture";
import { Dt } from "../common/dates";
import * as c from "../common/const";
import Sort from "../common/sort";
import "../common/global.scss";
import "./list.scss";
import { go } from "../../helpers/url";
import { withRouter } from "react-router-dom";
import { sortPres, sortCompare } from "../../helpers/sort";

const List = props => {
  const [plugs, setPlugs] = useState([]);
  const [showSort, setShowSort] = useState(false);
  const [sort, setSort] = useState("time-");

  const renderPlug = data => {
    const lnk = {
      open: "/update/" + data._id,
      new: "/assign/" + data._id,
      hold: "/update/" + data._id,
      closed: "/update/" + data._id,
      archived: "/history/" + data.outlet
    }[data.status];
    let dt = new Dt(data.time);
    let resolvedDt = new Dt(
      data.resolved_date ? data.resolved_date - data.time : 0
    );
    return (
      <div
        className={`plug status-${data.status}`}
        key={data._id}
        onClick={() => go(lnk)}
      >
        {/* <div className="plug-picture" style={{ backgroundImage: `url(${data.url})`, width: '100%', height: '100%', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></div> */}
        <div className="plug-content">
          <div className="plug-content-col">
            <Picture url={c.BACK_URL + "/images/" + data.url} fixedSize />
            <div></div>
            <div className="plug-text">
              <div className="plug-top">
                <div className="plug-date">
                  <div className="plug-time">{dt.time()}</div>
                  <div className="plug-ampm">{dt.ampm()}</div>
                  &nbsp;&nbsp;
                  <div
                    className={`plug-data plug-data-${
                      dt.farFromNow() ? "old" : "new"
                    }`}
                  >
                    {dt.customDate()}
                  </div>
                </div>
                <div
                  className={`plug-outlet ${
                    data.status === "new"
                      ? "plug-outlet-black"
                      : "plug-outlet-red"
                  }`}
                >
                  {data.outletName || "Undefined Outlet"}
                </div>
                <div className="plug-location">
                  {data.location || "Undefined Location"}
                </div>
                {/* {data.status === "new" && (
                <div className="plug-description">
                  {'"' + data.description + '"'}
                </div>
              )} */}
                {/* <div className="plug-ago">{dt.ago()}</div> */}
              </div>
            </div>
          </div>
          {(data.status === "closed" || data.status === "archived") && (
            <div className="plug-ago">
              Resolved in <strong className={resolvedDt.diffMore3Days() ? 'ft-red ft-medium-italic' : 'ft-black'}>{resolvedDt.diff()}</strong>
            </div>
          )}
          {(data.status === "hold") && (
            <div className="plug-ago">
              On Hold for <strong>{resolvedDt.diff()}</strong>
            </div>
          )}
          {(data.status === "closed" || data.status === "archived" || data.status === "hold") && (
            <div className="plug-resolved">
              <span className="plug-user">{data.resolved}</span> {'"' + data.comments + '"'}
            </div>
          )}
          {(data.status === "new" || data.status === "open") && (
            <div className="plug-description">
              {'"' + data.description + '"'}
            </div>
          )}
        </div>
        <div
          className={`plug-more ra-icon-chevron plug-more-${data.status}`}
        ></div>
      </div>
    );
  };

  // const { plugs } = props;
  // const params = useParams();

  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + "/alerts/listm", { credentials: 'include' });
      const resj = await res.json();
      setPlugs(resj);
    })();
  }, []);

  const filteredPlugs = plugs.filter(
    x => x.status === props.match.params.status
  );
  const headerLabel = {
    new: "New",
    hold: "On Hold",
    open: "Open",
    closed: "Closed",
    archived: "Archived"
  }[props.match.params.status];
  const sortOb = sortPres(sort);
  const sortedPlugs = filteredPlugs.sort((a, b) =>
    sortCompare(a[sortOb.prop], b[sortOb.prop], sortOb.dir)
  );
  const sortOptions = {
    new: ["time"],
    open: ["time", "outletName", "location"],
    hold: ["time", "outletName", "location"],
    closed: ["time", "outletName", "location", "resolved_date"],
    arhived: ["time", "outletName", "location", "resolved_date"]
  }[props.match.params.status];
  return (
    <div className="list">
      {showSort && (
        <Sort
          options={sortOptions}
          close={() => setShowSort(false)}
          value={sort}
          set={setSort}
        />
      )}
      <Header
        title={`${filteredPlugs.length} ${headerLabel}`}
        add={
          <div className="pointer" onClick={() => setShowSort(true)}>
            <span
              className={sortOb.dir === "asc" ? "ra-icon-up" : "ra-icon-down"}
            ></span>
            &nbsp;{sortOb.name}
          </div>
        }
        screen={1}
      />
      <Status
        type={props.match.params.status}
        caption={"Tap a photo to enter the Outlet Code"}
      />
      {sortedPlugs.map(x => renderPlug(x))}
      <div className="overscroll"></div>
    </div>
  );
};

export default withRouter(List);
