import React from "react";

export default class OutletCard extends React.Component {
  render() {
    const { id, type, location, amount } = this.props;
    return (
      <div className="outlet-card">
        {amount !== undefined && <div className="outlet-card-cnt">{amount || 0}</div>}
        <div className="outlet-card-type">{type}</div>
        <div className="outlet-card-id">{id}</div>
        <div className="outlet-card-location">{location}</div>
      </div>
    );
  }
}
