import * as c from '../components/common/const';

export function go(url) {
    document.location.href = url;
}

export function url(path) {
    return c.BASE_URL + path;
}

export function imageUrl(name) {
    return c.BACK_URL + '/images/' + name;
}

export const doUpload = async (file) => {
    const fdata = new FormData();
    fdata.append('file', file);
    fdata.append('name', file.name);

    const res = await fetch(c.BACK_URL + '/upload', { method: 'POST', body: fdata })
    const resj = await res.json();
    return resj.file;
}
