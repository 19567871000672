import React, { useState, useEffect } from "react";
import Header from "../common/header";
import Status from "../common/status";
import "./update.scss";
import { Dt } from "../common/dates";
import OutletCard from "../common/outletcard";
import Picture from "../common/picture";
// import Checkbox from '../common/checkbox';
import { Checkbox } from "semantic-ui-react";
import Select from "react-select";
import CancelButton from "../common/buttonCancel";
import SubmitButton from "../common/buttonSubmit";
import * as c from "../common/const";
import { withRouter } from "react-router-dom";
import { imageUrl, go } from "../../helpers/url";
import { connect } from "react-redux";
import { toggleGotIt } from "../../reducers/main";

const Update = props => {
  const [data, setData] = useState({});
  const [newStatus, setNewStatus] = useState("");
  const [comments, setComments] = useState("");
  const [assign, setAssign] = useState("");
  const [notify, setNotify] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async function getData() {
      const res = await fetch(
        c.BACK_URL + "/alerts/" + props.match.params.id + "/details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: props.token
          }
        }
      );
      const resj = await res.json();
      setData(resj);
      setNewStatus(resj.status);
    })();
    (async function getUsers() {
      const res = await fetch(c.BACK_URL + "/users/short", { credentials: 'include' });
      const resj = await res.json();
      setUsers(resj.map(x => ({ value: x._id, label: x.name })));
    })();
  }, []);
  useEffect(() => setNotify(assign), [assign]);

  const updateAlert = async () => {
    const ob = { comments: comments, status: newStatus };
    const res = await fetch(c.BACK_URL + "/alerts/update/" + data._id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: props.token
      },
      body: JSON.stringify(ob)
    });
    if (res.status === 200) {
      props.gotit("updated");
      document.location.href = "/list/" + data.status;
    }
  };

  const renderNewStatus = () => {
    if (data.status === "open" || data.status === "hold")
      return (
        <div className="update-status-list">
          <Checkbox
            // id="cbOpen" name="cbOpen" label="Open" value={newStatus === 'open'} checked={newStatus === 'open'} handleChange={() => setNewStatus('open')}
            label="Open"
            className={newStatus === "open" ? "active" : ""}
            checked={newStatus === "open"}
            onChange={() => setNewStatus("open")}
          />
          <Checkbox
            // id="cbHold" name="cbHold" label="On Hold" value={newStatus === 'hold'} checked={newStatus === 'hold'} handleChange={() => setNewStatus('hold')}
            label="On Hold"
            className={newStatus === "hold" ? "active" : ""}
            checked={newStatus === "hold"}
            onChange={() => setNewStatus("hold")}
          />
          <Checkbox
            // id="cbClosed" name="cbClosed" label="Closed" value={newStatus === 'closed'} checked={newStatus === 'closed'} handleChange={() => setNewStatus('closed')}
            label="Closed"
            className={newStatus === "closed" ? "active" : ""}
            checked={newStatus === "closed"}
            onChange={() => setNewStatus("closed")}
          />
        </div>
      );
    else
      return (
        <div className="update-status-list">
          <Checkbox
            label="Closed"
            className={newStatus === "closed" ? "active" : ""}
            checked={newStatus === "closed"}
            onChange={() => setNewStatus("closed")}
            disabled
          />
          <Checkbox
            label="Archived"
            className={newStatus === "archived" ? "active" : ""}
            checked={newStatus === "archived"}
            onChange={() => setNewStatus("archived")}
          />
        </div>
      );
  };

  // const params = useParams();
  // const { date, id, type, location, url, info  } = this.props;
  let dt = new Dt(data.date);
  const isOpen = data.status === "open";
  const allUsers = [{ value: undefined, label: "All Users" }, ...users];
  const textLabel = {
    open: "Additional Comments",
    hold: "Reason for Wait",
    closed: "Reason for Closed",
    archived: "Reason for Archive"
  }[newStatus];
  const textPlaceholder = {
    open: "Add comments to open alert",
    hold: "Describe reason for wait",
    closed: "Describe corrective action",
    archived: "Describe corrective action"
  }[newStatus];
  return (
    <div className="update">
      <Header title="Update Status" screen={3} />
      <Status
        type={data.status}
        caption={`${dt.time()}${dt.ampm()}, ${dt.dateFull()}`}
      />
      <div className="update-outlet">
        <OutletCard
          id={data.outlet}
          type={data.type}
          location={data.location}
          amount={data.alerts || 0}
        />
        {data.url && <Picture mayResize url={imageUrl(data.url)} />}
        <div className="outlet-info">"{data.description}"</div>
      </div>
      <div className="update-status">
        <div className="update-status-title">Update Status</div>
        {renderNewStatus()}
        <div className="update-status-field-title">{textLabel}</div>
        <textarea
          rows="4"
          value={comments}
          onChange={x => setComments(x.target.value)}
          placeholder={textPlaceholder}
        />
        {!!data.history && data.history.length > 0 && (
          <div className="update-history">
            <div className="update-history-tech-title">Technician Comments</div>
            <div className="update-history-data">
              {data.history.map(x => (
                <div>
                  <span className="update-history-date">
                    {new Dt(x.added_date).mdd()}
                  </span>
                  <span className="update-history-user">{x.user}</span>
                  <span className="update-history-comment">"{x.comment}"</span>
                </div>
              ))}
            </div>
          </div>
        )}
        {isOpen && (
          <div>
            <div className="update-status-field-title">Assign to:</div>
            <Select
              placeholder="Unassigned"
              options={users}
              value={users.find(x => x.value === assign)}
              onChange={e => setAssign(e.value)}
            />
          </div>
        )}
        {isOpen && (
          <div>
            <div className="update-status-field-title">Notify:</div>
            <Select
              placeholder="All users"
              options={allUsers}
              value={allUsers.find(x => x.value === notify)}
              onChange={e => setNotify(e.value)}
            />
          </div>
        )}
      </div>
      <div className="overscroll">
        <div className="buttons">
          <CancelButton
            onClick={() => go("/list/" + (data.status || "open"))}
          />
          <SubmitButton
            enabled={
              (data.status !== "closed" &&
                (newStatus === "open" ||
                  (newStatus === "hold" && comments !== "") ||
                  (newStatus === "closed" && comments !== ""))) ||
              (data.status === "closed" && newStatus === "archived")
            }
            onClick={() => updateAlert()}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  token: state.token
});

const mapDispatchToProps = dispatch => ({
  gotit: act => dispatch(toggleGotIt(act))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Update));
