import React from 'react';
import Title from '../common/title';
import Button from '../common/button';
import Checkbox from '../common/checkbox';
import * as c from '../common/const';

export default class LoginForm extends React.Component {

    renderField(value, caption, placeholder, type, handler) {
        return (
            <div className="field">
                <div className="field-title">{caption}</div>
                <input type={type} placeholder={placeholder} onChange={(ev) => handler(ev.target.value)} value={value} />
            </div>
        );
    }

    render() {
        const { keep, handleKeep, handleEmail, handlePassword, email, password, handleLogin, isLoginEnabled, isLoginFailure } = this.props;
        return (
            <div className="login-form">
                <Title />
                <div className="loginform">
                    <div className="loginform-title">
                        User Sign In
                    </div>
                    {this.renderField(email, 'Email', 'name@company.com', 'text', handleEmail)}
                    {this.renderField(password, 'Password', 'password here', 'password', handlePassword)}
                    <Checkbox 
                        id="keep"
                        label="Keep Me Signed In"
                        style="keep"
                        value={keep}
                        isChecked={keep}
                        handleChange={(ev) => handleKeep(ev)}
                    />
                    {/* <div className="keep">Keep Me Signed In</div> */}
                    <div className="addinfo">Only check this if your device will not be shared</div>
                    <Button 
                        caption="Sign In"
                        enabled={isLoginEnabled}
                        onClick={() => handleLogin()}
                        classAdd={isLoginFailure ? 'login-failure' : ''}
                    />
                    <div className="version">
                        {c.version}
                    </div>
                </div>
            </div>
        );
    }

}