import React from 'react';
import Button from './button';

export default class ButtonSubmit extends React.Component {
    render() {
        const { enabled, onClick, caption } = this.props;
        return (
            <Button caption={caption || 'Submit'} enabled={enabled} classAdd="submit" onClick={() => {if (enabled && onClick) onClick();}} />
        );

    }
}