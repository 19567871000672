import React from 'react'
import '../common/global.scss';
// import { OptionType } from '../common/interfaces';

// type PipeFilterProps = { value: any, options: Array<OptionType>, changeValue: Function };
// type PipeFilterState = {};

export default class PipeFilter extends React.Component {

    render() {
        const { value, options, changeValue } = this.props;
        const lastOption = options[options.length - 1];
        return (
            <div className="pipe-filter">
                {
                    options.map((el) => (
                        <div key={el.value} className={'pointer ' + (value === el.value ? 'ft-black' : 'ft-blue')} onClick={() => changeValue(el.value)}>
                            {el.label}
                        </div>
                    )).reduce((acc, x) => acc === null ? [x] : [acc, (<div className="ft-gray">&nbsp;&nbsp;|&nbsp;&nbsp;</div>), x], null)
                }
            </div>
        );
    }
}