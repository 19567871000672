import React from 'react';

export default class Status extends React.Component {

    render() {
        const { caption, type } = this.props;
        return (
            <div className={`status ${'status-' + type}`}>
                {caption}
            </div>
        );
    }
}