import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import CancelButton from "../common/buttonCancel";
import SubmitButton from "../common/buttonSubmit";
import { sortPres } from "../../helpers/sort";

const Sort = props => {
  const { close, value, set, options } = props;
  const [res, setRes] = useState(value);

  const listRender = [
    ...options.map(x => ({ value: x + "+", ...sortPres(x + "+") })),
    ...options.map(x => ({ value: x + "-", ...sortPres(x + "-") }))
  ].sort((a, b) => Math.sign((options.indexOf(a.prop) - options.indexOf(b.prop)) * 10 + (a.dir === 'asc' ? 1 : -1)) );
  return (
    <Modal open className="modal list sort" centered={false}>
      <Modal.Header className="modal-header">
        Outlet Sort Order{" "}
        <span className="ra-icon-cross" onClick={close}></span>
      </Modal.Header>
      <Modal.Content>
        <div>
          {listRender.map(x => (
            <div className="sort-piece" onClick={() => setRes(x.value)} key={x.value}>
              <div
                className={
                  "sort-piece-label " +
                  (res === x.value ? "sort-piece-label-active" : "")
                }
              >
                <span
                  className={x.dir === "asc" ? "ra-icon-up" : "ra-icon-down"}
                ></span>
                &nbsp;{x.name}
              </div>
              {res === x.value && (
                <div className="sort-piece-value ra-icon-check"></div>
              )}
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="footer">
          <CancelButton onClick={close} />
          <SubmitButton
            enabled
            onClick={() => {
              set(res);
              close();
            }}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default Sort;
