import React from "react";
import { Modal } from "semantic-ui-react";

const Gotit = (props) => {
  return (
    <Modal open className="modal" centered={false}>
      <Modal.Header className="modal-header">&nbsp;</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="successGuy"></div>
          <div className="modal-text">
              <div className="modal-text-title">
                Got It!
              </div>
              <div className="modal-text-subtitle">
                  Your <strong>Outlet Code</strong> has been {props.action}!
              </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default Gotit;
