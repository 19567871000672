import React, { useState, useEffect } from "react";
import "./home.scss";
import Container from "../../common/container";
import Banner from "../../common/banner";
import Picture from "../../common/picture";
import PipeFilter from "../../common/pipefilter";
import Periods from "../../common/periods";
import StackChart from "../../common/stackchart";
import { Dt } from "../../common/dates";
import { go } from "../../../helpers/url";
import Select from "react-select";
import * as c from "../../common/const";
import { periodBack } from "../../../helpers/period";

import { connect } from "react-redux";
import moment from "moment";

const Home = props => {
  const [active, setActive] = useState("");
  const [period, setPeriod] = useState(c.periods[2]);

  const [alerts, setAlert] = useState([]);
  const [showChart, setShowChart] = useState(true);

  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState();
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState();
  const [states, setStates] = useState([]);
  const [state, setState] = useState();

  const [filter, setFilter] = useState("");
  const [eventsHeight, setEventsHeight] = useState();
  const [eventsOutHeight, setEventsOutHeight] = useState();
  const [eventsInHeight, setEventsInHeight] = useState();
  const [showAllLoc, setShowAllLoc] = useState();
  const [showLocationsFilter, setShowLocationsFilter] = useState();
  const eventsRef = React.createRef();
  const eventsInRef = React.createRef();
  const eventsOutRef = React.createRef();

  const [recurring, setRecurring] = useState([]);
  const [locations, setLocations] = useState([]);
  const [stat, setStat] = useState({});

  //   useEffect(() => {
  //     (async function getData() {
  //       const res = await fetch(c.BACK_URL + "/alerts/listm");
  //       const resj = await res.json();
  //       setRecurring(resj);
  //     })();
  //   }, []);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + "/states/shortlist");
      const resj = await res.json();
      setStates(resj.map(x => ({ key: x.code, value: x.name })));
    })();
  }, []);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(
        c.BACK_URL + `/home/stat?period=${periodBack(period.value)}`
      );
      const resj = await res.json();
      setStat(resj);
    })();
  }, [period]);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(
        c.BACK_URL + `/home/recurring?period=${periodBack(period.value)}`
      );
      const resj = await res.json();
      setRecurring(resj);
    })();
  }, [period]);

  useEffect(() => {
    (async function getData() {
      const res = await fetch(
        c.BACK_URL + `/locations/list?period=${periodBack(period.value)}`, { credentials: 'include' }
      );
      const resj = await res.json();
      setLocations(resj);
    })();
  }, [period]);

  const renderTop = () => {
    return (
      <div className="home-top">
        <div>Organization Dashboard</div>
        <Periods period={period} setPeriod={setPeriod} />
      </div>
    );
  };
  const renderShowHide = (val, setVal, suffix) => {
    return (
      <div className="show-hide-menu">
        <span
          className={"pointer " + (val ? "black" : "blue")}
          onClick={() => setVal(true)}
        >
          Show {val ? "" : suffix}
        </span>
        &nbsp;
        <span className="gray">|</span>&nbsp;
        <span
          className={"pointer " + (val ? "blue" : "black")}
          onClick={() => setVal(false)}
        >
          Hide {val ? suffix : ""}
        </span>
      </div>
    );
  };

  const updSettings = () => {
    // TODO: Implement
  };
  const renderAlerts = (arrayToRender) => {
    // const arrayToRender = checks.map((x) => [x.value.re, x.value.mis, x.value.un, x.value.sch, x.value.pen || 0]) : [];
    const clientWidth = document.getElementById('alerts-container') ? document.getElementById('alerts-container').offsetWidth : document.body.clientWidth;
    return (
      <div className="alerts" id="alerts-container">
        <div className="alerts-title">
          <div className="alerts-title-title">
            New Alerts - <span className="red">{period.label}</span>
          </div>
        </div>
        {showChart && ( 
          <StackChart
            values={arrayToRender}
            period={period.value.replace("-", "")}
            data={['20200101', '20200201', '20200301', '20200401', '20200501', '20200601', '20200701', '20200801', '20200901', '20201001', '20201101', '20201201']}
            colors={["cnew"]}
            width={clientWidth}
            offset={(document.body.clientWidth - clientWidth) / 2}
            // width={500}
            title=""
          />
        )
        // <div className="checks-chart">
        //   {this.renderChecksGraph(arrayToRender, captions, ['f-gray', 'f-orange', 'f-blue', 'f-green'])}
        // </div>
        }
      </div>
    );
  };

  const renderDirection = (val, v = 12, classAdd) => {
    const dir =
      val === 1
        ? "" + v + ",0 0," + v + " " + v * 2 + "," + v
        : "0,0 " + v * 2 + ",0 " + v + "," + v;
    const dirClass =
      classAdd === undefined ? (val === 1 ? "dir-up" : "dir-down") : classAdd;
    return (
      <div className="direction">
        <svg height={v} width={v * 2}>
          <polygon points={dir} className={dirClass} />
        </svg>
      </div>
    );
  };
  const renderAgo = (x, colorValue, colorLabel) => {
    const days = Math.floor(x / (24 * 3600));
    const hours = Math.floor((x - days * 24 * 3600) / 3600);
    const mins = Math.max(
      Math.floor((x - days * 24 * 3600 - hours * 3600) / 60),
      1
    );
    return (
      <div>
        {!!x && days > 0 && (
          <span className={colorValue}>
            {days}
            <span className={colorLabel}>d</span>&nbsp;
          </span>
        )}
        {!!x && hours > 0 && (
          <span className={colorValue}>
            {hours}
            <span className={colorLabel}>h</span>&nbsp;
          </span>
        )}
        {!!x && mins > 0 && days === 0 && (
          <span className={colorValue}>
            {mins}
            <span className={colorLabel}>m</span>&nbsp;
          </span>
        )}
        {!x && <span className="ago-val-red">Never</span>}
      </div>
    );
  };

  const renderLocation = ob => {
    const ago = ob.last_action > 0 ? moment().unix() - ob.last_action : 0;
    return (
      <div key={ob.id} className="row">
        <div
          className={
            "ra-icon-loc-pin" + (ob.primary ? "-small" : "") + ` c${ob.status}`
          }
        >
          {ob.primary && <div className="loc-pin-small-primary">PRIMARY</div>}
        </div>
        <div className="row-name">
          <div
            className="row-name-name pointer"
            onClick={() => go(`/locations/${ob.id}/dashboard`)}
          >
            {ob.name}
          </div>
          <div className="row-name-city">
            {[ob.city, ob.state].filter(x => x !== "").join(", ")}
          </div>
          <div className="row-name-action">
            Last Action:&nbsp;
            {renderAgo(
              ago,
              ago < 2 * 24 * 3600 ? "ago-val" : "ago-val-red",
              "ago-label"
            )}
          </div>
        </div>
        <div className={`row-val row-val-red`}>
          <div
            className="pointer"
            onClick={() => go(`/locations/${ob.id}/room_checks`)}
          >
            {formatNumber(ob.assigned.toString())}
          </div>
          <div>{renderDirection(ob.assigned, 8)}</div>
        </div>
        <div className={`row-val row-val-blue`}>
          <div
            className="pointer"
            onClick={() => go(`/locations/${ob.id}/alerts`)}
          >
            {formatNumber(ob.closed.toString())}
          </div>
          <div>{renderDirection(ob.closed, 8)}</div>
        </div>
      </div>
    );
  };
  const renderLocations = arr => {
    const selVal = ev => (ev ? ev.value.toString() : "");
    const conv = x => (x ? { value: x.key, label: x.value } : undefined);
    return (
      <div
        className="home-data-locations"
        style={{ maxHeight: eventsHeight, paddingBottom: 50 }}
      >
        <div className="home-data-locations-title">
          Click on a Location Name
        </div>
        <div className="home-data-locations-container">
          <div>
            {renderShowHide(
              showLocationsFilter,
              v => {
                setShowLocationsFilter(v);
                updSettings("home_showfilter", v);
              },
              "Filters"
            )}
            {showLocationsFilter && (
              <div className="home-data-locations-filters">
                <div className="home-data-locations-filters-row">
                  {/* <Dropdown options={[{value: '1', label: 'group 1'}, {value: '2', label: 'group 2'}]} value={group} placeholder="Groups" /> */}
                  <div className="home-data-locations-filters-filter">
                    <Select
                      value={conv(groups.find(x => x.key === group))}
                      isClearable={true}
                      onChange={ev => {
                        setGroup(ev ? ev.value : undefined);
                        updSettings("home_location_group", selVal(ev));
                      }}
                      options={[
                        ...[{ label: "All Groups", value: "" }],
                        ...groups.map(x => {
                          return { value: x.key, label: x.value };
                        })
                      ]}
                      placeholder="Group"
                      styles={c.selectStyles}
                    />
                  </div>
                  <div className="home-data-locations-filters-filter">
                    <Select
                      value={conv(regions.find(x => x.key === region))}
                      isClearable={true}
                      onChange={ev => {
                        setRegion(ev ? ev.value : undefined);
                        updSettings("home_region", selVal(ev));
                      }}
                      options={[
                        ...[{ label: "All Regions", value: "" }],
                        ...regions.map(x => {
                          return { value: x.key, label: x.value };
                        })
                      ]}
                      placeholder="Region"
                      styles={c.selectStyles}
                    />
                  </div>
                  <div className="home-data-locations-filters-filter">
                    <Select
                      value={conv(states.find(x => x.key === state))}
                      isClearable={true}
                      onChange={ev => {
                        setState(ev ? ev.value : undefined);
                        updSettings("home_state", selVal(ev));
                      }}
                      options={[
                        ...[{ label: "All States", value: "" }],
                        ...states.map(x => {
                          return { value: x.key, label: x.value };
                        })
                      ]}
                      placeholder="State"
                      styles={c.selectStyles}
                    />
                  </div>
                </div>
                <div className="home-data-locations-filters-row">
                  <div className="home-data-locations-filters-search">
                    <input
                      placeholder="Search"
                      type="text"
                      value={filter}
                      onChange={ev => setFilter(ev.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row-header">
            <div className="home-data-locations-subtitle">
              <span className="home-data-locations-subtitle-val">
                {arr.length}
              </span>{" "}
              Locations
            </div>
            <div className="row-val header-title">New</div>
            <div className="row-val header-title">Closed</div>
          </div>
          <div
            style={{
              overflow: showAllLoc ? "scroll" : "hidden",
              //TODO: check logic
              maxHeight: showLocationsFilter || !showAllLoc ? "100%" : "100%"
            }}
            ref={eventsOutRef}
          >
            <div ref={eventsInRef} style={{ maxHeight: eventsHeight }}>
              {arr.map(x => renderLocation(x))}
            </div>
          </div>
          <div
            className={
              "home-data-locations-footer pointer " +
              (showAllLoc || eventsOutHeight >= eventsInHeight ? "hidden" : "")
            }
            onClick={() => setShowAllLoc(true)}
          >
            SHOW ALL
          </div>
        </div>
      </div>
    );
  };

  const statusClass = status => {
    const arr = [
      "red",
      "green",
      "yellow",
      "orange",
      "gray",
      "gray",
      "red",
      "black"
    ];
    return arr[parseInt(status, 10)] || "green";
  };

  const renderEventPeriod = (values, caption, colorClass) => {
    const lnth = values ? values.length : 1;
    const wAll = 72;
    const wOne = wAll / (lnth + 0.5 * (lnth - 1));
    const hAll = 40;
    const max = values ? Math.max(...values, 1) : 1;
    if (values) {
      return (
        <div className="period">
          <svg height={hAll} width={wAll}>
            {values.map((v, ind) => (
              <rect
                key={ind}
                width={wOne}
                height={(v / max) * hAll}
                y={hAll - (v / max) * hAll}
                x={ind * wOne * 1.5}
                className={colorClass}
              />
            ))}
          </svg>
          <div className="period-caption">{caption}</div>
        </div>
      );
    } else {
      return <div className="period"></div>;
    }
  };

  const renderEvent = (event, showPeriod, value, direction, classAdd, arr) => {
    const pres = {
      "-day": "7 days",
      day: "7 days",
      "-week": "7 days",
      week: "7 days",
      "-month": "4 weeks",
      month: "4 weeks",
      "-year": "4 quarters",
      year: "4 quarters"
    };
    return (
      <div className="home-data-events-event">
        <div className="event-title">
          {event}
          {showPeriod && (
            <span className="event-title-period">
              &nbsp;-&nbsp;{period.label}
            </span>
          )}
        </div>
        <div
          className={`value-container value-container-${
            Number.isInteger(direction) ? "center" : "baseline"
          }`}
        >
          <div className={"ind " + classAdd}>
            {formatNumber(value.toString())}
          </div>
          {Number.isInteger(direction) &&
            renderDirection(direction, 12, classAdd)}
          {!Number.isInteger(direction) && (
            <div className="value-unit">{direction}</div>
          )}
          {arr !== undefined && (
            <div>{renderEventPeriod(arr, pres["day"], classAdd)}</div>
          )}
        </div>

        {/* <div className="values">
          <div className="values-left">
            <div className="value-all">
              <div className="value-line-first">
                <div className={color1}>{formatNumber(values[0].value)}</div>
                <div className="value-line-first-direction">
                  {renderDirection(values[0].direction)}
                </div>
              </div>
              <div className="value-caption">{values[0].caption}</div>
            </div>
            <div className="value-all">
              <div className="value-line-first">
                <div className={color2}>{formatNumber(values[1].value)}</div>
                <div className="value-line-first-direction">
                  {renderDirection(values[1].direction)}
                </div>
              </div>
              <div className="value-caption">{values[1].caption}</div>
            </div>
          </div>
          <div className="values-right">
            <div>{renderEventPeriod(values[0].arr, cap, "f-" + color1)}</div>
            <div>{renderEventPeriod(values[1].arr, cap, "f-" + color2)}</div>
          </div>
        </div> */}
      </div>
    );
  };

  const formatNumber = num => {
    return !!num
      ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : num;
  };

  let locFiltered = locations.filter(x =>
    x.name.toLowerCase().includes(filter.toLowerCase())
  );
  if (state) locFiltered = locFiltered.filter(x => x.state === state);
  return (
    <Container
      breadcrumbs={[{ name: "Home", link: c.BASE_URL + "/portal/home" }]}
    >
      <div className="home">
        <Banner
          title={`Howdy, ${props.user.split(" ")[0]}`}
          subtitle="These are your organization-wide metrics, click on a location name to see more activity details"
          addClass="banner-subtitle-small"
        />
        {renderTop()}
        {renderAlerts(stat.byPeriod)}
        <div className="home-data">
          {renderLocations(locFiltered)}
          <div className="home-data-events" ref={eventsRef}>
            <div className="home-data-events-row">
              {renderEvent("New", true, stat.IDENTIFIED || 0, 1, "cnew", [])}
              {renderEvent("Closed", true, stat.CLOSED || 0, 1, "cclosed", [])}
            </div>
            <div className="home-data-events-row">
              {renderEvent(
                "Open",
                false,
                stat.open || 0,
                "",
                "copen",
                undefined
              )}
              {renderEvent(
                "Average Time to Close",
                false,
                2,
                "days",
                "ft-blue",
                undefined
              )}
            </div>
            <div className="home-data-events-row">
              <div className="home-data-events-event">
                <div className="event-title event-title-left">
                  Recurring
                  <span className="event-title-period">
                    &nbsp;-&nbsp;{period.label}
                  </span>
                </div>
                <div className="event-outlets">
                  <div></div>
                  <div className="event-outlets-title">Total Alerts</div>
                  <div className="event-outlets-title event-outlets-title-right">
                    Last
                  </div>
                  {recurring.map(x => (
                    <React.Fragment>
                      <div className="events-outlet">
                        <div e>
                          <Picture
                            url={c.BACK_URL + "/images/" + x.url}
                            fixedSize
                            size={{ width: "80px", height: "80px" }}
                          />
                        </div>
                        <div className="events-outlet-info">
                          <div className="events-outlet-title">Outlet Type</div>
                          <div className="events-outlet-code">
                            {x.outletName}
                          </div>
                          <div className="events-outlet-location">
                            {x.location}
                          </div>
                        </div>
                      </div>
                      <div className="events-outlet-alerts">{x.alerts}</div>
                      <div className="events-outlet-last">{(new Dt(x.last_alert)).customDate("MM/DD")}</div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
