import React, { useState } from "react";
import { url, go } from "../../helpers/url";
import { Redirect, withRouter } from "react-router-dom";
// import Menu from 'react-select/src/components/Menu';

const Menu = props => {
  const items = [
    { name: "Home", link: "/portal/home" },
    { name: "Locations", link: "/portal/locations" },
    { name: "Outlets", link: "/portal/outlets" },
    { name: "Users", link: "/portal/users" },
    { name: "Alerts", link: "/portal/alerts" }
    // { name: 'Support' }
  ];

  const renderItem = (name, ind, link) => {
    return (
      <div className="menu-item" key={ind}>
        <div className="pointer" onClick={() => go(link)}>
          {name}
        </div>
      </div>
    );
  };

  return (
    <div className="ra-menu">
      {items.map((x, i) => renderItem(x.name, i, x.link))}
    </div>
  );
};

export default withRouter(Menu);
