import React from 'react';

export default class Button extends React.Component {

    render() {
        const { caption, enabled, classAdd } = this.props;
        return (
            <div className={`button ${enabled ? '' : 'disabled'} ${classAdd}`} onClick={this.props.onClick && this.props.enabled ? () => this.props.onClick() : undefined} disabled={!enabled} >
                {caption}
            </div>
        )
    }

}