import React, { useState, useEffect } from 'react';
import Header from '../common/header';
import Status from '../common/status';
import OutletCard from '../common/outletcard';
import './history.scss';
import { Dt } from '../common/dates';
import { withRouter } from 'react-router-dom';
import * as c from '../common/const';

const History = (props) => {

    const [data, setData] = useState([]);
    const [outlet, setOutlet] = useState({});

    const renderAlert = (data) => {
        let dt = new Dt(data.dt);
        return (
            <div className="history-alert">
                <div className="history-date">{dt.monthDay()}  {dt.time()} {dt.ampm()}</div>
                <div className="history-ago">Resolved in 1 day</div>
                <div className="history-description"><span className="history-user">{data.assigned_to}</span> "{data.description}"</div>
            </div>
        );
    }

    useEffect(() => {
        (async function getData() {
            const res = await fetch(c.BACK_URL + '/alerts/history/' + props.match.params.id, { credentials: 'include' });
            const resj = await res.json();
            setData(resj);
            (async function getData() {
                const res2 = await fetch(c.BACK_URL + '/outlets/' + props.match.params.id + '/details');
                const res2j = await res2.json();
                setOutlet(res2j);
            })();
        })();
    }, [])

    return (
        <div className="history">
            <Header
                title="Alert History"
                add="Time"
            />
            <Status
                type="history"
                caption={`${data.length} Alerts`}
            />
            <OutletCard
                id={outlet.name}
                type={outlet.type}
                location={outlet.location}
            />
            {data.map((x) => renderAlert(x))}
        </div>
    );
}

export default withRouter(History);