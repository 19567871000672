import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import * as c from "../../common/const";
import Container from "../../common/container";
import Table from "../../common/table";
import { Form, Checkbox, Button, Input } from "semantic-ui-react";
import "./users.scss";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { sortCompare } from "../../../helpers/sort";
import Date from "../../common/date";
import Radio from "../../common/radio";
import Slider from "../../common/slider";

const UsersEdit = props => {
  // const [email, setEmail] = useState('');
  // const [mobile, setMobile] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [password, setPassword] = useState('');
  // const [password2, setPassword2] = useState('');
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState([]);
  const { permisssions } = props;

  const [emailError, setEmailError] = useState(undefined);

  const params = useParams();
  const id = params.id === "add" ? "" : params.id;

  const [tbl, setTbl] = useState([]);
  const [sort, setSort] = useState({ column: "name", order: "asc" });

  // Load data
  useEffect(() => {
    (async function getData() {
      if (id) {
        const res = await fetch(c.BACK_URL + "/users/" + id);
        const resj = await res.json();
        setData({
          ...resj,
          firstName: resj.userName.firstName,
          lastName: resj.userName.lastName
        });
        // setEmail(resj.email);
        // setMobile(resj.mobile);
        // setFirstName(resj.userName.firstName);
        // setLastName(resj.userName.lastName);
      }
      const res = await fetch(c.BACK_URL + "/permissions/list");
      const resj = await res.json();
      setPermissions(resj.map(x => ({ label: x.name, value: x.id })));
    })();
  }, []);

  // Load assignment
  useEffect(() => {
    (async function getData() {
      if (id) {
        const res = await fetch(
          c.BACK_URL + "/locations/assignment?user=" + id, { credentials: 'include' }
        );
        const resj = await res.json();
        setTbl(resj);
      }
    })();
  }, []);

  useEffect(() => {
    if ((data.email || "").includes("@") || data.email === "")
      setEmailError({});
    else {
      setEmailError({
        content: "Please enter a valid email address",
        pointing: "below"
      });
    }
  }, [data.email]);

  const userAdd = () => {
    // TODO: Frontend validation
    (async function mod() {
      let ob = {
        ...data,
        userName: { firstName: data.firstName, lastName: data.lastName }
      };
      if (
        data.passwordnew &&
        data.passwordnew2 &&
        data.passwordnew === data.passwordnew2
      )
        ob.password = data.passwordnew;
      let res = await fetch(c.BACK_URL + "/users/" + id, {
        method: id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8"
        },
        body: JSON.stringify(ob)
      });
      const resj = await res.json();
      if (res.status === 200) document.location.href = "/portal/users";
    })();
  };

  const userDelete = () => {
    (async function mod() {
      let res = await fetch(c.BACK_URL + "/users/" + id, {
        method: "DELETE"
      });
      document.location.href = "/portal/users";
    })();
  };

  const renderField = (label, name, isPassword = false) => {
    return (
      <Form.Field>
        <label>{label}</label>
        <input
          placeholder={label}
          value={data[name]}
          type={isPassword ? "password" : undefined}
          onChange={e => setData({ ...data, [name]: e.target.value })}
        />
      </Form.Field>
    );
  };

  const renderSelect = (label, name, options) => {
    return (
      <Form.Field>
        <label>{label}</label>
        <Select
          value={options.find(x => x.value === data[name])}
          placeholder={label}
          options={options}
          onChange={e => {
            setData({ ...data, [name]: e.value });
          }}
        />
      </Form.Field>
    );
  };

  const renderContact = (icon, label, data) => {
    return (
      <div className="ue-contact">
        <div className={"ue-icon " + icon}></div>
        <div className="ue-contact-info">
          <div className="ue-contact-info-label">{label}</div>
          <div className="ue-contact-info-data">{data}</div>
        </div>
      </div>
    );
  };

  const setAssign = (user, location, assigned, primary, following) => {
    fetch(c.BACK_URL + "/users/assign", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      credentials: "include",
      body: JSON.stringify({
        user: user,
        location: location,
        assigned: assigned,
        primary: primary,
        following: following
      })
    }).then(res => res.json())
    .then(resj => {
      setData({...data, locations: resj});
    });
  };

  // return (
  //   <Container
  //     breadcrumbs={[
  //       { name: "Home", link: c.BASE_URL },
  //       { name: "Users", link: c.BASE_URL + "/portal/users" },
  //       { name: id ? 'Edit' : 'Add', link: c.BASE_URL + "/portal/users/0" }
  //     ]}
  //   >
  //     <div className="users-edit">
  //       <Banner
  //         title="Users"
  //         subtitle="Manage profiles and settings for Plug Technicians."
  //       />
  //       {id && (
  //         <div className="delete">
  //           <Button color="red" onClick={() => userDelete()}>
  //             Delete
  //           </Button>
  //         </div>
  //       )}
  //       <Form>
  //         <Form.Field>
  //           <label>Active</label>
  //           <input
  //             checked={data.active}
  //             type="checkbox"
  //             onChange={e => setData({ ...data, active: e.target.checked }) }
  //           />
  //         </Form.Field>
  //         {renderField("First Name", "firstName")}
  //         {renderField("Last Name", "lastName")}
  //         {renderSelect("Permissions", "permissions", permissions)}
  //         {renderField("Email", "email")}
  //         {renderField("Mobile", "mobile")}
  //         {renderField("Password", "passwordnew", true)}
  //         {renderField("Retype Password", "passwordnew2", true)}
  //         {renderField("Comments", "comments")}
  //         {permissions === c.PERMISSIONS.RAAdmin &&
  //           renderField("Notes", "notes")}
  //         <Button type="submit" onClick={() => userAdd()}>
  //           Submit
  //         </Button>
  //       </Form>
  //     </div>
  //   </Container>
  // );

  const renderPrimary = (c, cl, row) => {
    let loc = data.locations ? data.locations.find(x => x.location == row._id) : undefined;
    return (
      <div className="primary center">
        <Radio
          checked={loc ? loc.primary : false}
          id={"radio" + row.id}
          name="assigned"
          value={row.id}
          label=" "
          onClick={() => setAssign(data._id, row._id, true, true)}
        />
      </div>
    );
  };

  const renderFollow = (c, cl, row) => {
    let loc = data.locations ? data.locations.find(x => x.location == row._id) : undefined;
    const value = loc ? loc.following : false;
    return (
      <div className="follow center">
        <Slider
          active={value}
          onClick={() => setAssign(data._id, row._id, true, undefined, !value)}
        />
      </div>
    );
  };
  const renderAssigned = (c, cl, row) => {
    let loc = data.locations ? data.locations.find(x => x.location == row._id) : undefined;
    return (
      <div className="follow center">
        <Slider
          active={!!loc}
          onClick={() => setAssign(data._id, row._id, !loc)}
        />
      </div>
    );
  };

  const renderFollowH = () => {
    return (
      <div>
        Following{" "}
        {/* <span
          className="ft-blue"
          // onClick={() => setFollow(true)}
        >
          Yes
        </span>
        /
        <span
          className="ft-gray"
          // onClick={() => setFollow(false)}
        >
          No
        </span> */}
      </div>
    );
  };

  const renderName = (cny, cl, row) => {
    return (
      <div>
        <span className="name">{c}</span>{" "}
        {row.unit ? (
          <span className="name-add">#{row.unit}</span>
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  const renderLast = v => {
    return <Date dt={v} showEmpty />;
  };

  const columns = [
    {
      id: "is_primary",
      clh: "center",
      name: "Assigned",
      fixed: true,
      f: renderAssigned
    },
    {
      id: "is_primary",
      clh: "center",
      name: "Primary",
      fixed: true,
      f: renderPrimary
    },
    {
      id: "name",
      clh: "left",
      name: "Location",
      fixed: true
      // f: renderName
    },
    // {
    //   id: "checked_in",
    //   clh: "center",
    //   name: "Following",
    //   fixed: true,
    //   f: renderFollow,
    //   fh: renderFollowH,
    //   noSort: true
    // },
    { id: "city", clh: "left", name: "City", cl: "city" },
    { id: "state", clh: "left", name: "State", cl: "state center" },
    { id: "last_action", clh: "center", name: "Last", f: renderLast }
  ];

  let rows = tbl;
  if (sort) {
    rows.sort((a, b) =>
      sortCompare(a[sort.column], b[sort.column], sort.order)
    );
  }

  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Users", link: c.BASE_URL + "/portal/users" },
        { name: id ? "Edit" : "Add", link: c.BASE_URL + "/portal/users/0" }
      ]}
    >
      <div className="users-edit">
        <div className="users-edit-header">
          <div className="users-edit-header-left">
            <div className="tech"></div>
            <div className="tech-id">ID: {data.userId}</div>
          </div>
          <div className="users-edit-header-right">
            <div className="ue-name">{data.name}</div>
            <div className="ue-info">
              <span className="ue-info-permission">Administrator</span>
              <span> | </span>
              <span className="ue-info-active">
                {data.active ? "Active" : "Inactive"}
              </span>
            </div>
            <div className="ue-contacts">
              {renderContact("ra-icon-phone-on", "Text", data.mobile)}
              {renderContact("ra-icon-email-on", "Email", data.email)}
            </div>
            {data.notes && (
              <div className="ue-notes">
                <div className="ue-notes-caption">Notes</div>
                <div className="ue-notes-data">{data.notes}</div>
              </div>
            )}
          </div>
        </div>
        <div className="ue-assignment-title">
          You’re a team member at these locations
        </div>
        <Table
          id="assignment"
          rows={rows}
          cnt={rows.length}
          columns={columns}
          chosenColumns={columns.map(x => x.id)}
          sort={sort}
          setSort={setSort}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  permissions: state.permissions
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersEdit);
