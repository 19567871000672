import React from "react";

export default class AlertGroup extends React.Component {
  render() {
    const { caption, cnt, onClick, classAdd, classAddCount } = this.props;
    return (
      <div className={"alert-group " + classAdd} onClick={onClick}>
        <div className="alert-group-left">
          <div className="alert-group-flag"></div>
          <div className="alert-group-caption">{caption}</div>
        </div>
        {cnt !== undefined && (
          <div className={"alert-group-count " + classAddCount}>{cnt}</div>
        )}
      </div>
    );
  }
}
