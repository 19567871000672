import React, { useEffect, useState } from "react";
import Banner from "../../common/banner";
import Container from "../../common/container";
import Table from "../../common/table";
import * as c from "../../common/const";
import Button from "../../common/button";
import "./locations.scss";
import { go } from "../../../helpers/url";
import { connect } from "react-redux";
import { sortCompare } from "../../../helpers/sort";
import Date from "../../common/date";
import PipeFilter from "../../common/pipefilter";
import Periods from "../../common/periods";
import FilterElement from "../../common/filterelement";
import Search from "../../common/search";
import { periodBack } from  '../../../helpers/period';


const Locations = props => {
  const columns = [
    {
      id: "id",
      name: " ",
      cl: "center",
      clh: "center",
      f: (v, c, row) => renderIcon(`ra-icon-pin cell-image c${row.status}`)
    },
    {
      id: "name",
      name: "Name",
      cl: "left pointer",
      clh: "left",
      click: r => () => go("/portal/locations/" + r._id)
    },
    { id: "outlets", name: "Outlets", cl: "center", clh: "center" },
    { id: "city", name: "City", cl: "left", clh: "left" },
    { id: "state", name: "State", cl: "center", clh: "center" },
    { id: "identified", name: "Alerts ID'd", cl: "center", clh: "center" },
    { id: "assigned", name: "Assigned", cl: "center", clh: "center" },
    { id: "closed", name: "Closed", cl: "center", clh: "center" },
    {
      id: "last_action",
      name: "Last Action",
      cl: "center",
      clh: "center",
      f: v => <Date dt={v} />
    }
  ];

  const [list, setList] = useState([]);
  const [sort, setSort] = useState({ column: "name", order: "asc" });
  const [active, setActive] = useState("");
  const [period, setPeriod] = useState(c.periods[2]);

  const [status, setStatus] = useState();
  const [filter, setFilter] = useState();
  const [activity, setActivity] = useState();

  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + `/locations/list?period=${periodBack(period.value)}`, { credentials: 'include' });
      const resj = await res.json();
      setList(resj);
    })();
  }, [period]);

  const renderIcon = iconClass => {
    return <div className={iconClass}></div>;
  };

  const renderTop = () => {
    return (
      <div className="top-filter">
        <div>
          <PipeFilter
            value={active}
            options={[
              { value: "", label: "All" },
              { value: "active", label: "Active" },
              { value: "inactive", label: "Deactivated" }
            ]}
            changeValue={setActive}
          />
        </div>
        <Periods period={period} setPeriod={setPeriod} />
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <div className="filter">
          <FilterElement
            caption="All Statuses"
            values={c.statuses}
            value={status}
            setValue={setStatus}
          />
          <FilterElement
            caption="All Locations"
            values={c.activity}
            value={activity}
            setValue={setActivity}
          />
        </div>
        <Search value={filter} setValue={setFilter} />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="buttons">
        <div className="buttons-title">
          <span className="ft-red">{sortedList.length}</span> Locations
        </div>
        <div className="buttons-right">
          <Button caption="Export to Excel" enabled classAdd="r-button" />
          <Button caption="Print" enabled classAdd="r-button" />
          <Button
            caption="+ Add New"
            enabled={false}
            classAdd="submit pointer button-new"
            onClick={() => {
              document.location.href = "/portal/locations/add";
            }}
          />
        </div>
      </div>
    );
  };

  let filteredList = list;
  if (status) filteredList = filteredList.filter(x => x.status === status);
  if (active) {const v = active === 'active'; filteredList = filteredList.filter(x => x.active === v);}
  if (activity !== undefined) filteredList = filteredList.filter(x => ((x.identified + x.assigned + x.closed) > 0) === activity );
  if (filter) {
    let lfilter = filter.toLowerCase();
    filteredList = filteredList.filter(
      x =>
        (x.name && x.name.toLowerCase().includes(lfilter)) ||
        (x.city && x.city.toLowerCase().includes(lfilter)) ||
        (x.state && x.state.toLowerCase().includes(lfilter)) ||
        (x.outlets && String(x.outlets).toLowerCase().includes(lfilter)) ||
        (x.identified && String(x.identified).toLowerCase().includes(lfilter)) ||
        (x.assigned && String(x.assigned).toLowerCase().includes(lfilter)) ||
        (x.closed && String(x.closed).toLowerCase().includes(lfilter))
    );
  }
  const sortedList = sort
    ? filteredList.sort((a, b) =>
        sortCompare(a[sort.column], b[sort.column], sort.order)
      )
    : filteredList;
  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Locations", link: c.BASE_URL + "/portal/locations" }
      ]}
    >
      <div className="users">
        <Banner
          title="Locations"
          subtitle="Manage your locations and review metrics."
        />
        {renderTop()}
        {renderTitle()}
        {renderFilter()}
        <Table
          id="locations"
          columns={columns}
          chosenColumns={columns}
          rows={sortedList}
          sort={sort}
          setSort={setSort}
        />
      </div>
    </Container>
  );
};

const mapStateToProps = state => ({
  token: state.token
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
