import React from 'react';
import { connect } from 'react-redux';

class OrgUser extends React.Component {
    
    render() {
        const { user, org, role } = this.props;
        // const { org, user, role } = this.props;
        // const org = 'Demo Organization';
        // const role = 'Manager';
        return (
            <div className="org-user">
                <div className="org-user-org">
                    <div className="org-user-org-main">{org}</div>
                </div>
                <div className="org-user-user">
                    <div className="org-user-user-main">{user}</div>
                    <div className="org-user-user-add">{role}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    org: state.org,
    role: state.role,

})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(OrgUser);