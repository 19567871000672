import React from 'react';
import '../common/global.scss'
import { go } from '../../helpers/url';

export default class Header extends React.Component {

    render() {
        const { title, add, screen } = this.props;
        return (
            <div className="header">
                <div className="header-menu ra-icon-back pointer" onClick={() => go('/login')}></div>
                <div className="header-title">{title}</div>
                <div className="header-add">{add}</div>
                <div className="header-screen">{screen}</div>
            </div>
        );
    }

}