import React, { useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { doUpload } from '../../../helpers/url';
import * as c from '../../common/const';
import Container from '../../common/container';
import Banner from '../../common/banner';
import './alerts.scss';

const Alert = function () {

    const [image, setImage] = useState();
    const [description, setDescription] = useState('');

    const alertAdd = () => {
        const ob = { image: image, description: description };
        fetch(c.BACK_URL + '/alerts', {
            method: 'POST', headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }, body: JSON.stringify(ob),
            credentials: 'include',
        });
    }

    return (
        <Container breadcrumbs={[{ name: 'Home', link: c.BASE_URL + '/portal/home' }, { name: 'Outlets', link: c.BASE_URL + '/portal/outlets' }]} >
            <div className="alert">
                <Banner
                    title="Alerts"
                    subtitle="Send Alert."
                />
                <Form>
                    <div className="upload">
                        <input type="file" accept="image/*" onChange={async (evt) => setImage(await doUpload(evt.target.files[0]))} />
                    </div>
                    <Form.Field>
                        <label>Comments</label>
                        <input
                            placeholder='Comments'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Field>
                    <Button type='submit' onClick={() => alertAdd()}>Submit</Button>
                </Form>
            </div>
        </Container>
    );
}

export default Alert;