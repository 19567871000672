import moment from 'moment';

export let version = 'v2.0 19.01.1';

const local = process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development';
console.log('ENV ' + process.env.NODE_ENV)

export const BASE_URL = window.location.protocol + '//' + window.location.hostname + (local ? ':3000' : '');
export const BACK_URL = window.location.protocol + '//' + window.location.hostname + (local ? ':4000' : '') + '/back';

export const PERMISSIONS = { Standard: 1, Admin: 2, SuperAdmin: 3, RAAdmin: 4 }

export const periods = [{ label: 'Today', value: 'day', period: [moment(), moment()] },
{ label: 'Yesterday', value: '-day', period: [moment().subtract(1, 'days'), moment().subtract(1, 'days')] },
{ label: 'This Week', value: 'week', period: [moment().startOf('isoWeek'), moment().endOf('isoWeek')] },
{
  label: 'Last Week', value: '-week', period: [moment().startOf('isoWeek').subtract(7, 'days'),
  moment().endOf('isoWeek').subtract(7, 'days')]
},
{ label: 'This Month', value: 'month', period: [moment().startOf('month'), moment().endOf('month')] },
{
  label: 'Last Month', value: '-month', period: [moment().subtract(1, 'month').startOf('month'),
  moment().subtract(1, 'month').endOf('month')]
},
{ label: 'This Year', value: 'year', period: [moment().startOf('year'), moment().endOf('year')] },
{
  label: 'Last Year', value: '-year', period: [moment().subtract(1, 'year').startOf('year'),
  moment().subtract(1, 'year').endOf('year')]
}];
export const shortPeriods = periods.slice(2, 6);

export const statuses = [{ value: 'new', label: 'New' }, { value: 'open', label: 'Open' }, { value: 'closed', label: 'Closed' }, 
{ value: 'hold', label: 'On Hold' }, { value: 'archived', label: 'Archived' }]

export const activity = [{ value: true, label: 'w/Activity'}, { value: false, label: 'w/ No Activity'}]

export const selectStyles = {
  dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.hasValue ? '#0d83dd' : provided.color,
  }),
  clearIndicator: (provided, state) => ({
      ...provided,
      color: state.hasValue ? '#0d83dd' : provided.color,
  })
}
