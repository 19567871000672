export function sortPres(val) {
    const options = { 
        'time-': { name: 'Time', dir: 'desc', prop: 'time', value: val },
        'time+': { name: 'Time', dir: 'asc', prop: 'time', value: val },
        'outletName+': { name: 'Outlet', dir: 'asc', prop: 'outletName', value: val },
        'outletName-': { name: 'Outlet', dir: 'desc', prop: 'outletName', value: val },
        'location+': { name: 'Location', dir: 'asc', prop: 'location', value: val },
        'location-': { name: 'Location', dir: 'desc', prop: 'location', value: val },
        'resolved_date+': { name: 'Resolved', dir: 'asc', prop: 'resolved_date', value: val },
        'resolved_date-': { name: 'Resolved', dir: 'desc', prop: 'resolved_date', value: val },
    }
    return options[val];
}

export function sortCompare(a, b, dir) {
    let res = 0;
    if (typeof a === 'number' && typeof b === 'number') res = Math.sign(dir === 'asc' ? a - b : b - a);
    if (typeof a === 'string' && typeof b === 'string') res = (dir === 'asc' ? 1 : -1) * a.localeCompare(b);
    return res;
}