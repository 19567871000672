import React from 'react'
import moment from 'moment';

export default class StackChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // width: (props.parent ? props.parent.offsetWidth : undefined) || document.body.clientWidth,
            width: props.width,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize() {
        // this.setState({ width: (this.props.parent ? this.props.parent.offsetWidth : undefined) || document.body.clientWidth });
        // this.setState({ width: this.props.width });
    }

    renderStack(values, width, xPos, unitHeight, height, colors) {
        return (
            <g key={xPos}>
                {values.map((x, ind) => <rect key={ind} className={colors[ind]} width={width} height={x * unitHeight} x={xPos} y={height - unitHeight * values.slice(0, ind + 1).reduce((a, b) => a + b, 0)} />)}
            </g>
        );
    }

    periodCaption(per, ind, period) {
        var v = '';
        var cap = '';
        if (period === 'hour') {
            cap = ['1a', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '1p', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'][ind];
        } else {
            if (period.includes('month')) {
                v = moment(per).format('dd').slice(0, 1);
            } else if (period.includes('year')) {
                v = moment(per).format('MMM');
            } else {
                v = moment(per).format('ddd');
            }
            cap = period.includes('year') ? per.substr(4, 2) : per.substr(6, 2);
        }

        return (
            <div>
                <div className="caption-num">{cap}</div>
                {period !== 'hour' &&
                    <div className="caption-day">{v}</div>
                }
            </div>
        );
    }

    formatNumber(num) {
        return !!num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
    }

    renderGraph(values, captions, colors) {
        const { width, offset } = this.props;
        const lnth = values ? values.length : 1;
        const wAll = width - 55 - 48 - 16 + offset/2;
        const wOne = (wAll - 20) / (lnth + 0.5 * (lnth - 1));
        const hAll = 150;
        const max = values ? Math.max(...values.map((x) => x.reduce((a, b) => a + b, 0)), 1) : 1;
        var shown = [];
        if (values) {
            return (
                <div>
                    <div className="chart">
                        <div className="chart-container">
                            <div className="y-axis">
                                {[4, 3, 2, 1, 0].map((x) => {
                                    let val = Math.round(x * max / 4);
                                    if (shown.includes(val)) {
                                        return (<div key={x}></div>);
                                    } else {
                                        shown.push(val);
                                        return (<div key={x} style={{ position: "absolute", right: '8px', top: '' + (hAll - 10 - x * hAll / 4) + 'px' }}>{val === 0 ? '' : this.formatNumber(val.toString())}</div>);
                                    }
                                })
                                }
                            </div>
                            <svg height={hAll + 2} width={wAll + 20}>
                                {[0, 1, 2, 3, 4].map((x) => <line key={x} x1="0" x2={wAll} y1={Math.max(x * hAll / 4, 0)} y2={Math.max(x * hAll / 4, 0)} className="chart-line" />)}
                                {values.map((v, ind) => this.renderStack(v, wOne, 10 + ind * wOne * 1.5, hAll / max, hAll, colors))}
                            </svg>
                        </div>
                        <div className="chart-captions">
                            {/* {captions.map((x: JSX.Element, ind: number) => (<div className="chart-caption" style={{ left: '' + (12 + 10 + 40 + ind * wOne * 1.5 + (wOne - 20) / 2) + 'px' }}>{x}</div>))} */}
                            {captions.map((x, ind) => (<div key={ind} className="chart-caption" style={{ left: '' + (3 * offset / 4 + 10 + 55 + 24 + ind * wOne * 1.5) + 'px', width: '' + wOne + 'px' }}>{x}</div>))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<div className="period"></div>);
        }
    }

    render() {
        const { values, period, data, title, colors } = this.props;
        const captions = data ? data.map((x, i) => this.periodCaption(x, i, period)) : [];
        return (
            <div className="checks-chart">
                <div className="chart-title">
                    {title}
                </div>
                {this.renderGraph(values, captions, colors)}
            </div>
        );
    }
}
