import React, { useState } from 'react';
import Top from './top';
import OrgUser from './orguser';
import Menu from './menu';
import Breadcrumb from './breadcrumb';
import Footer from './footer';


const Container = (props) => {
    const { breadcrumbs } = props;

    return (
        <div className="container-main">
            <Top />
            <div className="content">
                <OrgUser />
                <Menu />
                <Breadcrumb breadcrumbs={breadcrumbs} />
                {props.children}
                <Footer />
            </div>
        </div>
    );
}


export default Container;