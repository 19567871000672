import React from 'react';
import Header from '../common/header';
import Select from 'react-select';
import OutletCard from '../common/outletcard';
import CancelButton from '../common/buttonCancel';
import SubmitButton from '../common/buttonSubmit';
import './outlet.scss';

export default class Outlet extends React.Component {
    render() {
        return (
            <div className="outlet">
                    <Header
                        title="Edit Outlet"
                    />
                <div className="outlet-content">
                    <div className="outlet-title">Change Outlet Code</div>
                    <Select
                        placeholder="Type or select from dropdown"
                    />
                    <OutletCard
                        type={this.props.type}
                        id={this.props.id}
                        location={this.props.location}
                    />
                </div>
                <div className="overscroll">
                    <div className="buttons">
                        <CancelButton />
                        <SubmitButton />
                    </div>
                </div>
            </div>
        );
    };
}
