import React from 'react';
import Button from '../common/button'
import Title from '../common/title';

export default class Splash extends React.Component {

    render() {
        return (
            <div className="splash">
                <Title />
                <div className="man" />
                <div className="text">
                    <div>Report, notify and track</div>
                    <div>charging station issues in real-time</div>
                </div>
                <Button 
                    caption="Let's Get Started"
                    onClick={() => this.props.click()}
                    enabled
                />
            </div>
        );
    }

}