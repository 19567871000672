import { createSlice } from '@reduxjs/toolkit';
import * as c from '../components/common/const';

const mainSlice = createSlice({
    name: 'main',
    initialState: { isLoggedIn: false, user: undefined, isLoginFailure: false, permissions: 1, token: undefined, org: undefined, role: undefined, image: undefined, gotit: '' },
    reducers: {
        loginSuccess(state, action) {
            const { email, token, permissions, user, org, role } = action.payload;
            state.isLoggedIn = true;
            state.user = email;
            state.permissions = permissions;
            state.token = token;
            state.user = user;
            state.org = org;
            state.role = role;
        },
        loginFailure(state) {
            state.isLoginFailure = true;
        },
        loginFailureClear(state) {
            state.isLoginFailure = false;
        },
        logout(state, action) {
            state.isLoggedIn = false;
            localStorage.setItem('auth', undefined);
        },
        setImage(state, action) {
            state.image = action.payload.image;
        },
        showGotIt(state, action) {
            state.gotit = action.payload.show;

        }
    }
});

export const { loginSuccess, loginFailure, loginFailureClear, logout, setImage, showGotIt } = mainSlice.actions

export default mainSlice.reducer

export const fetchLogin = (email, password) => async dispatch => {
    const res = await fetch(c.BACK_URL + '/login', { method: 'POST', headers: {
                'Content-Type': 'application/json;charset=utf-8' }
            , body: JSON.stringify({ email: email, password: password }), credentials: 'include'});
    const resj = await res.json();
    if (res.status === 200 && resj.success === true) {
        localStorage.setItem('auth', JSON.stringify({ isLoggedIn: true, user: email, token: resj.token, permissions: resj.permissions, user: resj.user, org: resj.org, role: resj.role }))
        dispatch(loginSuccess({email: email, token: resj.token, permissions: resj.permissions, user: resj.user, org: resj.org, role: resj.role }));
    } else {
        dispatch(loginFailure());
        setTimeout(() => dispatch(loginFailureClear()), 1000)
    }
}

export const toggleGotIt = (act) => dispatch => {
    dispatch(showGotIt({ show: act }));
    setTimeout( () => dispatch(showGotIt({ show: '' })), 2000);
}