import React, { useState, useEffect } from "react";
import Container from "../../common/container";
import Table from "../../common/table";
import Banner from "../../common/banner";
import PipeFilter from "../../common/pipefilter";
import Date from "../../common/date";
import Periods from "../../common/periods";
import * as c from "../../common/const";
import FilterElement from "../../common/filterelement";
import { sortCompare } from "../../../helpers/sort";
import { Dt } from "../../common/dates";
import Search from "../../common/search";
import Button from "../../common/button";
import { periodBack } from "../../../helpers/period";

const Alerts = props => {
  const columns = [
    {
      id: "active",
      name: " ",
      cl: "center",
      clh: "center",
      f: (v, c, row) => renderOutlet(`bg${row.status || "no"}`)
    },
    {
      id: "time",
      name: "Received",
      cl: "left",
      clh: "left",
      f: v => <Date dt={v} showEmpty />
    },
    {
      id: "assigned",
      name: "ID'd",
      cl: "left",
      clh: "left",
      f: v => <Date dt={v} showEmpty />
    },
    {
      id: "elapsed",
      name: "Elapsed",
      cl: "left",
      clh: "left",
      f: (v, c, row) => row.resolved_date > 0 ? (new Dt(v)).diff(1) : ''
    },
    {
      id: "resolved_date",
      name: "Closed",
      cl: "left",
      clh: "left",
      f: v => <Date dt={v} showEmpty />
    },
    {
      id: "location",
      name: "Location",
      cl: "left pointer",
      clh: "left"
      //   click: r => () => go("/portal/users/" + r._id)
    },
    {
      id: "outletName",
      name: "Outlet",
      cl: "left pointer",
      clh: "left"
    },
    {
      id: "description",
      name: "Issue",
      cl: "left",
      clh: "left"
    },
    {
      id: "comments",
      name: "Resolution",
      cl: "left",
      clh: "left"
    },
    {
      id: "resolved",
      name: "User",
      cl: "left",
      clh: "left"
    },
  ];

  const [sort, setSort] = useState({ column: "time", order: "desc" });

  const [status, setStatus] = useState("open");
  const [period, setPeriod] = useState(c.periods[2]);
  const [list, setList] = useState([]);

  const [filter, setFilter] = useState("");
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);
  const [activity, setActivity] = useState();

  useEffect(() => {
    (async function getLocations() {
      const res = await fetch(c.BACK_URL + "/locations/shortList", {
        method: "GET",
        headers: { Authorization: props.token },
        credentials: 'include'
      });
      const resj = await res.json();
      setLocations(resj.map(x => ({ label: x.name, value: x._id })));
    })();
    (async function getUsers() {
      const res = await fetch(c.BACK_URL + "/users/short", {
        method: "GET",
        headers: { Authorization: props.token },
        credentials: 'include',
      });
      const resj = await res.json();
      setUsers(resj.map(x => ({ label: x.name, value: x._id })));
    })();
  }, []);
  useEffect(() => {
    (async function getData() {
      const res = await fetch(c.BACK_URL + `/alerts/listp?period=${periodBack(period.value)}`, { credentials: 'include' });
      const resj = await res.json();
      setList(resj);
    })();
  }, [period]);

  const renderOutlet = iconClass => {
    return (
      <div className={"outlet-cont " + iconClass}>
        <div className={"outlet cell-image "}></div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="top-filter">
        <div>
          <PipeFilter
            value={status}
            options={[
              { value: "", label: "All" },
              { value: "open", label: "Open" },
              { value: "hold", label: "Waiting" },
              { value: "closed", label: "Closed" }
            ]}
            changeValue={setStatus}
          />
        </div>
        <Periods period={period} setPeriod={setPeriod} />
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <div className="filter-container">
        <div className="filter">
          <FilterElement
            caption="All Locations"
            values={locations}
            value={location}
            setValue={setLocation}
          />
          <FilterElement
            caption="All Users"
            values={users}
            value={user}
            setValue={setUser}
          />
        </div>
        <Search value={filter} setValue={setFilter} />
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="buttons">
        <div className="buttons-title">
          <span className="ft-red">{sortedList.length}</span> Alerts
        </div>
        <div className="buttons-right">
          <Button caption="Export to Excel" enabled classAdd="r-button" />
          <Button caption="Print" enabled classAdd="r-button" />
          <Button
            caption="+ Add New"
            enabled={false}
            classAdd="submit pointer button-new"
            onClick={() => {
              document.location.href = "/portal/users/add";
            }}
          />
        </div>
      </div>
    );
  };

  let filteredList = list;
  if (status) {
    filteredList = filteredList.filter(x => x.status === status);
  }
  if (location) {
    filteredList = filteredList.filter(x => x.location_id === location);
  }
  if (user) {
    filteredList = filteredList.filter(x => x.user_id === user);
  }
  if (filter) {
    let lfilter = filter.toLowerCase();
    filteredList = filteredList.filter(
      x =>
        (x.location && x.location.toLowerCase().includes(lfilter)) ||
        (x.outletName && x.outletName.toLowerCase().includes(lfilter)) ||
        (x.description && x.description.toLowerCase().includes(lfilter)) ||
        (x.comments && x.comments.toLowerCase().includes(lfilter)) ||
        (x.resolved && x.resolved.toLowerCase().includes(lfilter)) 
    );
  }
  const sortedList = sort
    ? filteredList.sort((a, b) =>
        sortCompare(a[sort.column], b[sort.column], sort.order)
      )
    : filteredList;
  return (
    <Container
      breadcrumbs={[
        { name: "Home", link: c.BASE_URL + '/portal/home' },
        { name: "Alerts", link: c.BASE_URL + "/portal/alerts" }
      ]}
    >
      <div className="users">
        <Banner
          title="Alerts"
          subtitle="Monitor outlet issues across multiple facilities."
        />
        {renderTop()}
        {renderTitle()}
        {renderFilter()}
        <Table
          id="alerts"
          columns={columns}
          chosenColumns={columns}
          rows={sortedList}
            sort={sort}
            setSort={setSort}
        />
      </div>
    </Container>
  );
};

export default Alerts;
