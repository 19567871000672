import React from 'react';

export default class Breadcrumb extends React.Component {

    renderItem(name, link, ind) {
        return (
            <div className="breadcrumb-item pointer" key={ind}>
                <a href={link}>{name}</a>
            </div>
        );
    }

    renderSeparator(key) {
        return (
            <div className="separator" key={key}>
                /
            </div>
        );
    }

    render() {
        const { breadcrumbs } = this.props;
        // const items = [{ name: 'Home', link: c.BASE_URL }, { name: 'Users', link: c.BASE_URL + '/portal/users'}]
        const back = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2].link : breadcrumbs[breadcrumbs.length - 1].link
        const itemsToRender = [];
        for (let i = 0; i < breadcrumbs.length; i++) {
            if (i !== 0) itemsToRender.push(this.renderSeparator('s' + i));
            itemsToRender.push(this.renderItem(breadcrumbs[i].name, breadcrumbs[i].link, i));
        }
        return (
            <div className="ra-breadcrumb">
                <div className="breadcrumb-left">
                    {itemsToRender.map((v) => v)}
                </div>
                <div className="breadcrumb-right">
                    <a href={back}>&lt; Back</a>
                </div>
            </div>
        );
    }
}