import React from 'react'
import '../common/global.scss';

export default class Search extends React.Component {

    callHook() {
        const { hook } = this.props;
        if (typeof hook === 'function') {
            hook();
        }
    }

    render() {
        const { value, setValue } = this.props;
        return (
            <div className="search">
                <input 
                placeholder="Search" 
                type="text" value={value} 
                onChange={ev => setValue(ev.target.value)} 
                onBlur={() => this.callHook()}></input>
            </div>
        );
    }
}